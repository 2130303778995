<template>
    <div class=" q-pa-lg ">
        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-lg">Estas seguro de eliminar el paciente.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="PutEstadoPaciente()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOGO PARA CREAR UN NUEVO PACIENTE -->
        <q-dialog full-width v-model="dialogNuevoPaciente" persistent transition-show="scale" transition-hide="scale">
            <Paciente @cierraVentana="dialogNuevoPaciente = false"></Paciente>
        </q-dialog>

        <div class="row	flex-left ">
            <div class="col-12 col-md-6 col-sm-6 text-left ">
                <span v-if="$store.state.isDark == true"
                    class="q-pa-lg shadow-6 mb-3 inline-block surface-card logo-inicio"
                    style="border-radius: 10px; width: 230px; height: 50px;">
                </span>

                <span v-else class="q-pa-lg shadow-0 mb-3 inline-block surface-card logo-inicio-negro"
                    style=" width: 230px; height: 50px;">
                </span>
                <!-- <div class="doc-h2" style="font-size:2rem;">Pacientes</div> -->
            </div>
            <div class="col-12 col-md-6 col-sm-6 text-right ">
                <q-btn size="md" class="btn-estilos q-mr-sm" icon="mdi-plus" unelevated rounded
                    style="background:#1A4161; color:white" label="Nuevo Paciente" @click="nuevoPaciente()" />
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-update"
                    @click="GetPacientes()" />
                <!-- <q-btn round color="primary" icon="mdi-plus" @click="nuevoPaciente()" /> -->
            </div>
            <div class="element-linea"></div>
        </div>


        <div v-if="$store.state.isDark == false" class="row q-mt-md ">
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards">
                        <div class="text-subtitle1">Total Pacientes</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalPcientes }}</span>
                            <q-icon name="mdi-account-group-outline" size="3rem" style="color: #ACACAC;" color="white"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards">
                        <div class="text-subtitle1">Mujeres</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalMujeres }}</span>
                            <q-icon name="mdi-gender-female" size="3rem" style="color: #ACACAC;" color="white"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards">
                        <div class="text-subtitle1">Hombres</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalHombres }}</span>
                            <q-icon name="mdi-gender-male" size="3rem" style="color: #ACACAC;" color="white"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards">
                        <div class="text-subtitle1">Edad Promedio</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ edadPromedio }}</span>
                            <q-icon name="mdi-chart-multiple" size="3rem" style="color: #ACACAC;" color="white"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
        </div>

        <div v-else class="row q-mt-md ">
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards-dark">
                        <div class="text-subtitle1">Total Pacientes</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalPcientes }}</span>
                            <q-icon name="mdi-account-group-outline" size="3rem" style="color: #686868;"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards-dark">
                        <div class="text-subtitle1">Mujeres</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalMujeres }}</span>
                            <q-icon name="mdi-gender-female" size="3rem" style="color: #686868;" class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards-dark">
                        <div class="text-subtitle1">Hombres</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalHombres }}</span>
                            <q-icon name="mdi-gender-male" size="3rem" style="color: #686868;" class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards-dark">
                        <div class="text-subtitle1">Edad Promedio</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ edadPromedio }}</span>
                            <q-icon name="mdi-chart-multiple" size="3rem" style="color: #686868;" class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
        </div>

        <div class="q-mt-md">
            <q-table class="shadow-0 header-tabla " title="Pacientes" :data="items" :columns="columns" row-key="nombre"
                :filter="filter" :loading="loading" :pagination="pagination" no-data-label="Sin datos disponibles">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarPaciente(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                            <q-btn size="md" style="color:#1A4161" rounded flat dense @click="editarPaciente(props.row)"
                                icon="mdi-pencil">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                        <q-td key="apellidos" :props="props">{{ props.row.apellidos }}</q-td>
                        <q-td key="edad" :props="props">{{ props.row.edad }}</q-td>
                        <q-td key="genero" :props="props">{{ props.row.genero }}</q-td>
                        <q-td key="fechaNacimiento" :props="props">{{ props.row.fechaNacimiento }}</q-td>
                        <q-td key="fechaElaboracion" :props="props">{{ props.row.fechaElaboracion }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
</template>
<script>

import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import Paciente from './Paciente.vue'
export default {
    components: { Paciente },
    data() {
        return {
            filter: '',
            loading: false,
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'apellidos', align: 'left', label: 'Apellidos', field: 'apellidos', sortable: true },
                { name: 'edad', align: 'center', label: 'Edad', field: 'edad' },
                { name: 'genero', align: 'center', label: 'Genero', field: 'genero' },
                { name: 'fechaNacimiento', align: 'center', label: 'Fecha de nacimiento', field: 'fechaNacimiento' },
                { name: 'fechaElaboracion', align: 'center', label: 'Fecha de elaboracion', field: 'fechaElaboracion' },
            ],
            data: [],
            dialogNuevoPaciente: false,
            pagination: {
                sortBy: 'nombre',
                descending: false,
                page: 1,
                rowsPerPage: 10
                // rowsNumber: xx if getting data from a server
            },
            dialogConfirm: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaPacienteStore
        },
        totalPcientes() {
            return this.$store.state.listaPacienteStore.length
        },
        totalMujeres() {
            let mujeres = []
            mujeres = this.$store.state.listaPacienteStore.filter(x => x.genero == "Femenino")
            return mujeres.length
        },
        totalHombres() {
            let hombres = []
            hombres = this.$store.state.listaPacienteStore.filter(x => x.genero == "Masculino")
            return hombres.length
        },
        edadPromedio() {
            const pacientes = this.$store.state.listaPacienteStore;

            if (pacientes.length === 0) {
                return 0; // Evita dividir por cero si la lista está vacía
            }

            const sumaEdades = pacientes.reduce((total, paciente) => {
                return total + parseInt(paciente.edad);
            }, 0);
            const promedio = sumaEdades / pacientes.length;
            return Math.round(promedio);
        }
    },
    created() {
        this.GetPacientes();
    },
    methods: {
        async GetPacientes() {
            this.loading = true
            try {
                let response = await axios.get("Paciente/GetDatosPacientes/dev/" + this.token.username);
                let catalogo = response.data;
                this.$store.state.listaPacienteStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },

        async PutEstadoPaciente() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando paciente. Espere...', messageColor: 'white' })
            let paciente = this.$store.state.pacienteStore

            try {
                let response = await axios.put('Paciente/PutEstadoPaciente/dev/' + paciente._id)
                console.log(response)

                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.items.indexOf(paciente)
                this.items.splice(indice, 1)


                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El paciente ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },

        async GetPacienteOne(paciente) {
            try {
                let response = await axios.get("Paciente/GetPaciente/dev/" + paciente);
                return response.data;

            } catch (error) {
                console.log(error);
            }
        },

        nuevoPaciente() {
            let paciente = {
                _id: '',
                estatus: 'Vigente',
                fechaElaboracion: null,
                especialidad: '',
                referencia: '',
                apellidos: '',
                nombre: '',
                fechaNacimiento: null,
                edad: 0,
                genero: '',
                seguroMedico: '',
                numPoliza: '',
                vive: '',
                datosContacto: {
                    correo: '',
                    pais: '',
                    celular: '',
                    calle: '',
                    numInterior: '',
                    numExterior: '',
                    codigoPostal: '',
                    colonia: '',
                    estado: '',
                },
                contactoUrgencia: {
                    nombre: '',
                    correo: '',
                    pais: '',
                    celular: '',
                },
                contactoResponsable: {
                    nombre: '',
                    correo: '',
                    pais: '',
                    celular: '',
                },
                antecHeredofammiliares: [],
                antecPersonalesNoPatologicos: {
                    procedencia: {
                        pais: '',
                        estado: '',
                        municipio: {
                            estado: '',
                            municipio: '',
                        },
                    },
                    residencia: {
                        pais: '',
                        estado: '',
                        municipio: {
                            estado: '',
                            municipio: '',
                        },
                    },
                    escolaridad: '',
                    religion: '',
                    ocupacion: '',
                    estadoCivil: '',
                    convAnimales: [],
                    zoonosis: [],
                    actividadesFisicas: [],
                    comidasDia: 1,
                    calidadAlimentacion: '',
                    intolerancias: [],
                    alergias: [],
                    hemotipo: '',
                    exposicionBiomasa: {
                        horas: 0,
                        años: 0,
                    },
                    tabaquismo: {
                        numCigarros: 0,
                        años: 0,
                        indice: 0,
                    },
                    alcoholismo: [],
                    toxicomanias: [],
                },
                antecPersonalesPatologicos: {
                    transfusionesPrevias: {
                        numeroTransfusion: 0,
                        tiposTransfusiones: [],
                        motivosTransfusiones: [],
                        fechaUltimaTransfusion: '',
                        reaccionesAdversas: [],
                    },
                    enfermedadesCronicos: [],
                    prodedimientosQuirurgicos: [],
                    traumatismosPrevios: [],
                },
                antecGinecoObstetricos: {
                    activa: '',
                    actividadSexual: {
                        edadVidaSexual: 0,
                        numGestas: 0,
                        numPartos: 0,
                        numCesareas: 0,
                        numAbortos: 0,
                        numParejas: 0,
                        orientacion: '',
                        practicasRiesgo: '',
                        mpf: '',
                        enfermedadesTS: [],
                    },
                    edadMenarca: '',
                    estatusMenopausia: '',
                    menopausia: {
                        cicloMenstrual: '',
                        frecuencia: 0,
                        diasSangrado: 0,
                    },
                    fechaUltimaMenstruacion: null,
                },
                antecAndrologicos: {
                    activa: '',
                    actividadSexual: {
                        edadVidaSexual: 0,
                        numParejas: 0,
                        orientacion: '',
                        mpf: '',
                        practicasRiesgo: '',
                        enfermedadesTS: [],
                    },
                    circuncidado: '',
                    edadPubarca: 0,
                },
                antecOncologicos: {
                    terapias: [],
                    radioterapias: [],
                    procedimientos: [],
                    diagnosticoPrevioCancer: 'No',
                    sitiosTumorPrimario: []
                },
                padecimientoActual: {
                    fechaInicio: null,
                    sintomas: [],
                    diagnosticos: [],
                    descripcion: '',
                },
                usuarioCreador: this.token.username
            }

            this.$store.state.pacienteStore = paciente

            this.dialogNuevoPaciente = true
        },

        async editarPaciente(data) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 100, message: 'Consultando datos. Espere...', messageColor: 'white' })
            let response = await this.GetPacienteOne(data._id);
            console.log(response)
            this.$store.state.pacienteStore = { ...response }
            this.$store.state.indexPaciente = this.$store.state.listaPacienteStore.indexOf(response)
            this.$q.loading.hide()
            this.dialogNuevoPaciente = true
        },

        eliminarPaciente(item) {
            this.$store.state.pacienteStore = item
            this.dialogConfirm = true
        },

    }
}
</script>
<style></style>