import { render, staticRenderFns } from "./DrawerPerfil.vue?vue&type=template&id=2c2a05d2"
import script from "./DrawerPerfil.vue?vue&type=script&lang=js"
export * from "./DrawerPerfil.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QImg from 'quasar/src/components/img/QImg.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QImg,QAvatar,QCard,QCardSection,QList,QItemLabel,QSeparator,QItem,QItemSection,QIcon,QFooter,QToggle});qInstall(component, 'directives', {Ripple});
