<template>
    <q-page class=" q-pa-lg ">
        <!-- DIALOGO PARA CREAR UN NUEVA CONSULTA -->
        <q-dialog full-width v-model="dialogNuevaConsulta" persistent transition-show="scale" transition-hide="scale">
            <Consulta @cierraVentana="dialogNuevaConsulta = false"></Consulta>
        </q-dialog>

        <!-- DIALOGO PARA VER LOS ESTUDIOS -->
        <q-dialog full-width v-model="dialogFolder" persistent transition-show="scale" transition-hide="scale">
            <q-card>
                <q-card-section class="row items-center q-pb-none">
                    <div class="text-h6">{{ this.paciente }}</div>
                    <q-space />
                    <q-btn icon="close" flat round dense v-close-popup />
                </q-card-section>

                <q-card-section>
                    <q-table title="Estudios" :data="itemsEstudios" :columns="columnsEstudios" row-key="tipo">
                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td auto-width key="acciones">
                                    <q-btn size="md" color="blue" rounded flat dense @click="verArchivo(props.row)"
                                        icon="mdi-file-eye">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                            content-style="font-size: 14px">Ver</q-tooltip>

                                    </q-btn>
                                    <q-btn size="md" color="green" rounded flat dense
                                        @click="descargarArchivoBase64(props.row.base64, props.row.nombreArchivo, props.row.tipoArchivo)"
                                        icon="mdi-download">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                            content-style="font-size: 14px">Descargar</q-tooltip>
                                    </q-btn>
                                </q-td>
                                <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                                <q-td key="estudio" :props="props">{{ props.row.estudio }}</q-td>
                                <q-td key="nombreArchivo" :props="props">{{ props.row.nombreArchivo }}</q-td>
                                <q-td key="fecha" :props="props">{{ props.row.fecha }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- DIALOGO PARA VER IMAGENES -->
        <q-dialog v-model="dialogImagenes" persistent transition-show="scale" transition-hide="scale">
            <q-card style="width: 700px; max-width: 80vw;">
                <q-card-section class="row items-center q-pb-none">
                    <div class="text-h6">{{ this.estudio }}</div>
                    <q-space />
                    <q-btn icon="close" flat round dense v-close-popup />
                </q-card-section>

                <q-card-section>
                    <q-img :src="url" spinner-color="white" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA MOSTRAR LOS PDF -->
        <q-dialog full-width v-model="dialogVisor" persistent transition-show="scale" transition-hide="scale">
            <VisorPdf @CloseDialogPdf="dialogVisor = false"></VisorPdf>
        </q-dialog>

        <div class="row	flex-left ">
            <div class="col-12 col-md-6 col-sm-6 text-left ">
                <span v-if="$store.state.isDark == true"
                    class="q-pa-lg shadow-6 mb-3 inline-block surface-card logo-inicio"
                    style="border-radius: 10px; width: 230px; height: 50px;">
                </span>

                <span v-else class="q-pa-lg shadow-0 mb-3 inline-block surface-card logo-inicio-negro"
                    style=" width: 230px; height: 50px;">
                </span>
            </div>

            <div class="col-12 col-md-6 col-sm-6 text-right ">
                <q-btn size="md" class="btn-estilos q-mr-sm" icon="mdi-plus" unelevated rounded
                    style="background:#1A4161; color:white" label="Nueva Consulta" @click="nuevaConsulta()" />
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-update"
                    @click="GetConsultas()" />
                <!-- <q-btn round color="primary" icon="mdi-plus" @click="nuevaConsulta()" /> -->
            </div>
            <div class="element-linea"></div>
        </div>

        <div v-if="$store.state.isDark == false" class="row q-mt-md ">
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards">
                        <div class="text-subtitle1">Total Consultas</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalConsultas }}</span>
                            <q-icon name="mdi-hospital-building" size="3rem" style="color: #ACACAC;" color="white"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards">
                        <div class="text-subtitle1">Total Pacientes</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalPcientes }}</span>
                            <q-icon name="mdi-account-group-outline" size="3rem" style="color: #ACACAC;" color="white"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards">
                        <div class="text-subtitle1">Total Citas</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalCitas }}</span>
                            <q-icon name="mdi-calendar-clock" size="3rem" style="color: #ACACAC;" color="white"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards">
                        <div class="text-subtitle1">Edad Promedio</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ edadPromedio }}</span>
                            <q-icon name="mdi-chart-multiple" size="3rem" style="color: #ACACAC;" color="white"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
        </div>

        <div v-else class="row q-mt-md ">
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards-dark">
                        <div class="text-subtitle1">Total Consultas</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalConsultas }}</span>
                            <q-icon name="mdi-hospital-building" size="3rem" style="color: #686868;" class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards-dark">
                        <div class="text-subtitle1">Total Pacientes</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalPcientes }}</span>
                            <q-icon name="mdi-account-group-outline" size="3rem" style="color: #686868;"
                                class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3 q-pr-lg">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards-dark">
                        <div class="text-subtitle1">Total Citas</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ totalCitas }}</span>
                            <q-icon name="mdi-calendar-clock" size="3rem" style="color: #686868;" class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-3">
                <q-card class="full-width shadow-0">
                    <q-card-section class="q-cards-dark">
                        <div class="text-subtitle1">Edad Promedio</div>
                        <div class="flex items-center justify-center text-h4">
                            <span>{{ edadPromedio }}</span>
                            <q-icon name="mdi-chart-multiple" size="3rem" style="color: #686868;" class="q-ml-lg" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
        </div>

        <div class="q-mt-md">
            <q-table class="shadow-0 header-tabla " title="Consultas" :data="items" :columns="columns" row-key="_id"
                :filter="filter" :loading="loading" :pagination="pagination" no-data-label="Sin datos disponibles">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <!-- <q-td auto-width key="acciones">
                            <q-btn size="md" color="red" rounded flat dense @click="eliminarPaciente(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>

                            </q-btn>
                            <q-btn size="md" color="primary" rounded flat dense @click="editarPaciente(props.row)"
                                icon="mdi-pencil">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </q-td> -->
                        <q-td key="nombrePaciente" :props="props">{{ props.row.nombrePaciente }}</q-td>
                        <q-td key="motivo" :props="props">{{ props.row.motivo }}</q-td>
                        <q-td key="fechaConsulta" :props="props">{{ props.row.fechaConsulta }}</q-td>
                        <q-td auto-width key="estudios">
                            <template v-if="$store.state.isDark">
                                <!-- ESTUDIOS -->
                                <q-btn size="md" style="color:#686868" rounded flat dense
                                    @click="verEstudios(props.row)" icon="mdi-folder-multiple">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Ver Estudios</q-tooltip>
                                </q-btn>
                                <!-- MEDICAMENTOS -->
                                <q-btn size="md" style="color:#686868" rounded flat dense @click="GetReceta(props.row)"
                                    icon="mdi-medication">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Receta</q-tooltip>
                                </q-btn>
                                <!-- INFORME MEDICO -->
                                <q-btn size="md" style="color:#686868" rounded flat dense
                                    @click="GetInformeMedico(props.row)" icon="mdi-clipboard-pulse">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Informe médico</q-tooltip>
                                </q-btn>
                            </template>

                            <template v-if="!$store.state.isDark">
                                <!-- ESTUDIOS -->
                                <q-btn size="md" style="color:#1A4161" rounded flat dense
                                    @click="verEstudios(props.row)" icon="mdi-folder-multiple">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Ver Estudios</q-tooltip>
                                </q-btn>
                                <!-- MEDICAMENTOS -->
                                <q-btn size="md" style="color:#1A4161" rounded flat dense @click="GetReceta(props.row)"
                                    icon="mdi-medication">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Receta</q-tooltip>
                                </q-btn>
                                <!-- INFORME MEDICO -->
                                <q-btn size="md" style="color:#1A4161" rounded flat dense
                                    @click="GetInformeMedico(props.row)" icon="mdi-clipboard-pulse">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Informe médico</q-tooltip>
                                </q-btn>
                            </template>
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </q-page>
</template>
<script>
import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import Consulta from './Consulta.vue'
import print from 'print-js'
import { PdfMedicamentos } from '../../Pdf/PdfMedicamentos.js';
import { PdfInformeMedico } from '../../Pdf/PdfInformeMedico.js';
import VisorPdf from '../../Pdf/VisorPdf.vue'

export default {
    components: { Consulta, VisorPdf },
    data() {
        return {
            dialogNuevaConsulta: false,
            filter: '',
            loading: false,
            columns: [
                // { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'nombrePaciente', align: 'left', label: 'Nombre', field: 'nombrePaciente', sortable: true },
                { name: 'motivo', align: 'left', label: 'Motivo', field: 'motivo' },
                { name: 'fechaConsulta', align: 'center', label: 'Fecha de Consulta', field: 'fechaConsulta', sortable: true },
                { name: 'estudios', align: 'center', label: 'Ver Estudios', field: 'estudios' },
            ],
            pagination: {
                sortBy: 'nombrePaciente',
                descending: true,
                page: 1,
                rowsPerPage: 10
                // rowsNumber: xx if getting data from a server
            },

            columnsEstudios: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'tipo', align: 'left', label: 'Tipo de estudio', field: 'tipo', sortable: true },
                { name: 'estudio', align: 'left', label: 'Nombre del estudio', field: 'estudio', sortable: true },
                { name: 'nombreArchivo', align: 'left', label: 'Nombre del archivo', field: 'motivo' },
                { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', sortable: true },
            ],
            dialogFolder: false,
            itemsEstudios: [],
            paciente: '',
            dialogImagenes: false,
            estudio: '',
            url: '',

            //MUESTRA EL PDF
            dialogVisor: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        items() {
            return this.$store.state.listaConsultasStore
        },

        totalPcientes() {
            return this.$store.state.listaPacienteStore.length
        },

        totalConsultas() {
            return this.$store.state.listaConsultasStore.length
        },

        totalCitas() {
            return 0;
        },

        edadPromedio() {
            const pacientes = this.$store.state.listaPacienteStore;

            if (pacientes.length === 0) {
                return 0; // Evita dividir por cero si la lista está vacía
            }

            const sumaEdades = pacientes.reduce((total, paciente) => {
                return total + parseInt(paciente.edad);
            }, 0);
            const promedio = sumaEdades / pacientes.length;
            return Math.round(promedio);
        },

        idMedico() {
            //OBTENEMOS EL ID DEL USUARIO ACTIVO
            const listUsuarios = this.$store.state.listaEmpleadosStore;
            const usuario = this.token.username;
            const idUsuario = listUsuarios.find((f) => f.username === usuario)._id;
            return idUsuario;
        },
    },
    created() {
        this.GetConsultas();

        this.GetPacientes();

        this.GetEmpleados();

    },
    methods: {
        async nuevaConsulta() {
            this.$store.state.pacienteConsultaStore = {
                paciente: ''
            }
            let consulta = {
                _id: '',
                fechaConsulta: new Date(),
                nombrePaciente: '',
                idPaciente: '',
                motivo: '',
                analisisSubjetivo: {
                    sintomas: []
                },
                analisisObjetivo: {
                    TAD: 0,
                    TAS: 0,
                    abdomenPelvis: [],
                    cabezaCuello: [],
                    extremidades: {
                        sintomas: [],
                        partesCuerpo: [],
                    },
                    frecuenciaCardiaca: 0,
                    frecuenciaRespiratoria: 0,
                    neurologicos: [],
                    pruebasLaboratorioImagen: [],
                    pulsioximetria: 0,
                    tegumentos: {
                        sintomas: [],
                        partesCuerpo: [],
                    },
                    tensionArterialMedia: 0,
                    temperatura: 0,
                    toraxEspalda: [],
                },
                diagnosticoPlan: {
                    diagnosticos: [],
                    dietaHabitos: '',
                    medicamentos: {
                        medicamentos: [],
                        otrasIndicaciones: ''
                    },
                    pronosticos: [],
                    solicitudInterconsulta: [],
                    ordenHospitalizacion: {
                        hospital: '',
                        motivoInternamiento: '',
                        indicaciones: {
                            dieta: '',
                            medidasGenerales: '',
                            liquidosIntravenosos: '',
                            medicamentos: '',
                            estudios: ''
                        }
                    },

                },
                informeMedico: {
                    apellidos: '',
                    nombre: '',
                    fechaNacimiento: '',
                    genero: '',
                    edad: '',
                    fechaEmision: null,
                    infoDiagnostico: {
                        diagnosticos: [],
                        fechaDiagnonostico: '',
                        caracteristicas: '',
                        estadioDiagnostico: '',
                    },
                    antecHeredofammiliares: [],
                    antecPersonalesNoPatologicos: {
                        alergias: [],
                        hemotipo: '',
                        exposicionBiomasa: {
                            horas: 0,
                            años: 0,
                        },
                        tabaquismo: {
                            numCigarros: 0,
                            años: 0,
                            indice: 0,
                        },
                        toxicomanias: [],
                        alcoholismo: [],
                    },
                    antecPersonalesPatologicos: {
                        enfermedadesCronicos: [],
                        prodedimientosQuirurgicos: [],
                        traumatismosPrevios: [],
                    },
                    antecOncologicos: {
                        terapias: [],
                        radioterapias: [],
                        procedimientos: [],
                    },
                    historialPadecimiento: {
                        sintomas: []
                    },
                    comentarios: ''
                },
                usuarioCreador: this.token.username
            }
            this.$store.state.consultaStore = { ...consulta }
            await this.GetPacientes();
            this.dialogNuevaConsulta = true
        },

        async GetPacientes() {
            try {
                let response = await axios.get("Paciente/GetDatosPacientes/dev/" + this.token.username);
                let catalogo = response.data;
                this.$store.state.listaPacienteStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetConsultas() {
            this.loading = true
            try {
                let response = await axios.get("Paciente/GetDatosConsulta/dev/" + this.token.username);
                let catalogo = response.data;
                this.$store.state.listaConsultasStore = catalogo;
                this.loading = false
            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },

        async verEstudios(item) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 100, message: 'Consultando datos. Espere...', messageColor: 'white' })
            let data = await this.GetConsultaOne(item._id);
            this.paciente = item.nombrePaciente

            // if (data.analisisObjetivo.pruebasLaboratorioImagen.estudioImagenologico.estudio != '') {
            //     let objeto = {
            //         tipo: 'Estudio Imagenológico',
            //         estudio: data.analisisObjetivo.pruebasLaboratorioImagen.estudioImagenologico.estudio,
            //         nombreArchivo: data.analisisObjetivo.pruebasLaboratorioImagen.estudioImagenologico.nombreArchivo,
            //         fecha: data.analisisObjetivo.pruebasLaboratorioImagen.estudioImagenologico.fecha,
            //         base64: data.analisisObjetivo.pruebasLaboratorioImagen.estudioImagenologico.base64,
            //         tipoArchivo: data.analisisObjetivo.pruebasLaboratorioImagen.estudioImagenologico.tipoArchivo,
            //     }
            //     this.itemsEstudios.push(objeto)
            // }

            // if (data.analisisObjetivo.pruebasLaboratorioImagen.estudioLaboratorio.estudio != '') {
            //     let objeto = {
            //         tipo: 'Estudio de laboratorio',
            //         estudio: data.analisisObjetivo.pruebasLaboratorioImagen.estudioLaboratorio.estudio,
            //         nombreArchivo: data.analisisObjetivo.pruebasLaboratorioImagen.estudioLaboratorio.nombreArchivo,
            //         fecha: data.analisisObjetivo.pruebasLaboratorioImagen.estudioLaboratorio.fecha,
            //         base64: data.analisisObjetivo.pruebasLaboratorioImagen.estudioLaboratorio.base64,
            //         tipoArchivo: data.analisisObjetivo.pruebasLaboratorioImagen.estudioLaboratorio.tipoArchivo,
            //     }
            //     this.itemsEstudios.push(objeto)
            // }

            // if (data.analisisObjetivo.pruebasLaboratorioImagen.pruebasMoleculares.estudio != '') {
            //     let objeto = {
            //         tipo: 'Pruebas Moleculares',
            //         estudio: data.analisisObjetivo.pruebasLaboratorioImagen.pruebasMoleculares.estudio,
            //         nombreArchivo: data.analisisObjetivo.pruebasLaboratorioImagen.pruebasMoleculares.nombreArchivo,
            //         fecha: data.analisisObjetivo.pruebasLaboratorioImagen.pruebasMoleculares.fecha,
            //         base64: data.analisisObjetivo.pruebasLaboratorioImagen.pruebasMoleculares.base64,
            //         tipoArchivo: data.analisisObjetivo.pruebasLaboratorioImagen.pruebasMoleculares.tipoArchivo,

            //     }
            //     this.itemsEstudios.push(objeto)
            // }

            this.itemsEstudios = data.analisisObjetivo.pruebasLaboratorioImagen
            this.$q.loading.hide()

            this.dialogFolder = true

        },

        async GetConsultaOne(paciente) {
            try {
                let response = await axios.get("Paciente/GetConsulta/dev/" + paciente);
                return response.data;

            } catch (error) {
                console.log(error);
            }
        },

        verArchivo(item) {
            let documento = item.base64
            let tipo = item.nombreArchivo.slice(-3)
            if (tipo == 'pdf') {
                print({
                    printable: documento.split(",")[1],
                    type: 'pdf',
                    base64: true,
                })
            } else {
                this.url = documento
                this.dialogImagenes = true
                this.estudio = item.estudio
            }
        },

        descargarArchivoBase64(base64Data, nombreArchivo, tipoArchivo) {
            var base64 = base64Data.split(",")[1].trim();
            // Decodificar los datos Base64
            var blob = this.base64toBlob(base64, tipoArchivo);

            // Crear un objeto URL para el blob
            var blobUrl = URL.createObjectURL(blob);

            // Crear un enlace para descargar el archivo
            var enlaceDescarga = document.createElement('a');
            enlaceDescarga.href = blobUrl;
            enlaceDescarga.download = nombreArchivo;

            // Añadir el enlace al documento
            document.body.appendChild(enlaceDescarga);

            // Simular un clic en el enlace para iniciar la descarga
            enlaceDescarga.click();

            // Eliminar el enlace después de la descarga
            document.body.removeChild(enlaceDescarga);
        },

        // Función para convertir una cadena Base64 a un objeto Blob
        base64toBlob(base64Data, tipoArchivo) {
            var byteString = atob(base64Data);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var uint8Array = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
                uint8Array[i] = byteString.charCodeAt(i);
            }

            return new Blob([arrayBuffer], { type: tipoArchivo });
        },

        async GetReceta(item) {
            const idPaciente = item.idPaciente
            const listaPacientes = this.$store.state.listaPacienteStore;
            const paciente = listaPacientes.find(f => f._id === idPaciente)
            if (!paciente) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Verifique los datos del paciente.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }
            if (!paciente.celular) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Verifique los datos del paciente.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }
            if (paciente.celular === "") {
                this.$q.notify({
                    type: 'warning',
                    message: 'Verifique los datos del paciente.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 100, message: 'Consultando datos. Espere...', messageColor: 'white' })
                let _id = item._id;
                let response = await axios.get("Paciente/GetMedicamentosIdConsultaAsync/dev/" + _id);
                //CONSULTAMOS LA CONFIGURACION DE LOS DOCUMENTOS
                const ObjConfig = await this.GetConfiguracionDocumentos();
                const ObjPerfil = await this.GetPerfilMedico();

                if (!ObjConfig) {
                    this.$q.notify({
                        type: 'warning',
                        message: 'Indique los campos de "Configuración de documentos" para generar el documento, ubicado en el apartado de configuracion en el submenu superior derecho',
                        actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                    })
                    return;
                }

                if (!ObjPerfil) {
                    this.$q.notify({
                        type: 'warning',
                        message: 'Indique los campos de "Perfil médico" para generar el documento, ubicado en el apartado de configuracion en el submenu superior derecho',
                        actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                    })
                    return;
                }

                const objMedicamentos = { ...response.data };
                const objPaciente = {
                    nombre: item.nombrePaciente,
                    fecha: item.fechaConsulta,
                };
                const nombreArchivo = _id;
                if (objMedicamentos.medicamentos.length != 0) {
                    const tipoHoja = 1;
                    let base64 = await PdfMedicamentos(objMedicamentos, objPaciente, nombreArchivo, tipoHoja, ObjConfig, ObjPerfil);
                    let objPdf = {
                        base64: base64,
                        nombreArchivo: item._id + ".pdf",
                        tipo: "Receta",
                        telefono: paciente.celular,
                    }
                    this.$store.state.pdfStore = { ...objPdf };
                    this.dialogVisor = true;
                } else {
                    this.$q.notify({
                        type: 'warning',
                        message: 'La consulta no cuenta con lista de medicamentos',
                        actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                    })
                }
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'warning',
                    message: 'La consulta no cuenta con lista de medicamentos',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
            }
        },

        async GetInformeMedico(item) {
            const idPaciente = item.idPaciente
            const listaPacientes = this.$store.state.listaPacienteStore;
            const paciente = listaPacientes.find(f => f._id === idPaciente)
            if (!paciente) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Verifique los datos del paciente.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }
            if (!paciente.celular) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Verifique los datos del paciente.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }
            if (paciente.celular === "") {
                this.$q.notify({
                    type: 'warning',
                    message: 'Verifique los datos del paciente.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 100, message: 'Consultando datos. Espere...', messageColor: 'white' })
                let _id = item._id;
                let response = await axios.get("Paciente/GetInformeMedicoIdConsultaAsync/dev/" + _id);
                const objInforme = { ...response.data }
                const nombreArchivo = _id + ".pdf";
                const tipoHoja = 1;

                //CONSULTAMOS LA CONFIGURACION DE LOS DOCUMENTOS
                const ObjConfig = await this.GetConfiguracionDocumentos();
                const ObjPerfil = await this.GetPerfilMedico();

                if (!ObjConfig) {
                    this.$q.notify({
                        type: 'warning',
                        message: 'Indique los campos de "Configuración de documentos" para generar el documento, ubicado en el apartado de configuracion en el submenu superior derecho',
                        actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                    })
                    return;
                }

                if (!ObjPerfil) {
                    this.$q.notify({
                        type: 'warning',
                        message: 'Indique los campos de "Perfil médico" para generar el documento, ubicado en el apartado de configuracion en el submenu superior derecho',
                        actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                    })
                    return;
                }

                let base64 = await PdfInformeMedico(objInforme, nombreArchivo, tipoHoja, ObjConfig, ObjPerfil);
                //VISTA PREVIA DEL DOCUMENTO
                let objPdf = {
                    base64: base64,
                    nombreArchivo: item._id + ".pdf",
                    tipo: "Informe medico",
                    telefono: paciente.celular,
                    nombrePaciente: paciente.nombre + " " + paciente.apellidos
                }
                this.$store.state.pdfStore = { ...objPdf };
                this.dialogVisor = true;

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
            this.$q.loading.hide()
        },

        //PARA LOS PDFS
        async GetEmpleados() {
            try {
                let response = await axios.get("Login/GetEmpleados/dev");
                let catalogo = response.data;
                this.$store.state.listaEmpleadosStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetConfiguracionDocumentos() {
            try {
                let response = await axios.get("Configuracion/GetConfiguracionDocumentosAsync/dev/" + this.idMedico);
                let x = { ...response.data }
                return x;
            } catch (error) {
                console.log(error);
            }
        },

        async GetPerfilMedico() {
            try {
                let response = await axios.get("Configuracion/GetPerfilMedicoAsync/dev/" + this.idMedico);
                let x = { ...response.data }
                return x;
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>