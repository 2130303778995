<template>
    <div class="q-pa-md">
        <q-card style="max-width: 500px">
            <q-card-section>
                <div class="row no-wrap items-center rounded-borders">
                    <div class="text-h6">
                        Constanza
                    </div>
                    <q-space />
                    <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="forum"
                    @click="NuevoChat()" >
                        <q-tooltip content-class="primary" content-style="font-size: 16px" :offset="[10, 10]">
                            Nueva conversación
                        </q-tooltip>
                    </q-btn>
                </div>
                <div class="element-linea"></div>
                <div class="row q-col-gutter-sm">
                    <!-- TIPO DE MENSAJE -->
                    <div class="col-12">
                        <q-select dense v-model="tipoMensaje" filled
                            :options="itemsTipoMensaje"
                            label="Tipo de Mensaje" />
                    </div>
                    <!-- PACIENTE -->
                    <div class="col-12" v-if="tipoMensaje.value === 'patients'">
                        <q-select v-model="selectPaciente" emit-value map-options
                            @filter="filtroPacientes" :options="itemsfiltroPacientes" use-input
                            input-debounce="0" option-label="paciente" dense filled label="Paciente"
                            virtual-scroll-slice-size="1" new-value-mode="add" />
                    </div>
                </div>
            </q-card-section>
            <q-card-section>
                <q-scroll-area style="height: 200px; max-width: 500px;" :bar-style="barStyle" ref="scrollArea">
                    <div class="q-pa-md row justify-center">
                        <div style="width: 100%; max-width: 400px">
                            <template v-for="m in  mensajes ">
                                <!-- USUARIO -->
                                <q-chat-message v-if="m.tipo === 'user'" :name="token.username"
                                    :avatar="avatarUsuario" :text="[m.mensaje]" text-color="white" sent
                                    bg-color="green-9" />
                                <!-- CONSTANZA -->
                                <q-chat-message v-if="m.tipo === 'assistant'" name="Constanza" :avatar="avatarConstanza" :text="[m.mensaje]" text-color="white" bg-color="primary" />
                            </template>
                            <template v-if="loadingMensaje">
                                <q-chat-message :avatar="avatarConstanza" name="Constanza" bg-color="primary">
                                    <q-spinner-dots class="q-ml-sm" size="2rem" />
                                </q-chat-message>
                            </template>
                        </div>
                    </div>
                </q-scroll-area>
            </q-card-section>
            <q-card-section>
                <div class="row q-col-gutter-sm">
                    <div class="col-12">
                        <q-input filled rounded bottom-slots @keydown.enter="GuardaMensaje($event)" v-model="text"
                            label="Mensaje" dense>
                            <!-- <template v-slot:after>
                                <q-btn round dense flat icon="send" @click="GuardaMensaje()" />
                            </template> -->
                        </q-input>
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import { format } from "date-fns";

export default {
    components: {

    },
    data() {
        return {
            tipoMensaje: "General",
            itemsTipoMensaje: [
                { label:"General", value: "general" },
                { label:"Buscar sobre un paciente", value: "patients" },
                { label:"Artículos científicos", value: "papers" },
                { label:"Noticias en internet", value: "news" },
            ],
            text: null,
            mensajes: [],
            loadingMensaje: false,

            barStyle: {
                right: '2px',
                borderRadius: '9px',
                backgroundColor: '#027be3',
                width: '9px',
                opacity: 0.2
            },

            position: 0,
            respuesta: '',

            avatarConstanza: "",
            avatarUsuario: require('../../assets/logo_contago.png'),
            colorUsuario: "",

            //PACIENTES
            selectPaciente: null,
            itemsfiltroPacientes: null,

            //HISTORIAL DEL CHAT
            idHistorial: null,
            chat_id: "",
            banderaNuevo: false,

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        itemsPacientes() {
            return this.$store.state.listaPacienteStore
        },

    },

    watch: {

    },

    created() {
        this.GetPacientes();
        this.GetHistorialChat();
    },

    methods: {
        async GetIniciales() {
            this.GetPerfilMedico();
            this.GetConfiguracionDocumentos();
        },

        async GetAvataConstanza() {
            if (this.$store.state.isDark) {
                this.avatarConstanza = require("../../assets/icono_constanza_blanco.png")
            } else {
                this.avatarConstanza = require("../../assets/icono_consulta_azul.png")
            }
        },

        async GetPerfilMedico() {
            try {
                const id = this.token._id;
                let response = await axios.get("Configuracion/GetPerfilMedicoAsync/dev/" + id);
                const x = { ...response.data }
                if (x.urlMedico != "") {
                    this.avatarUsuario = x.urlMedico;
                }
            } catch (error) {
                console.log(error);
            }
        },

        async GetConfiguracionDocumentos() {
            try {
                const id = this.token._id;
                let response = await axios.get("Configuracion/GetConfiguracionDocumentosAsync/dev/" + id);
                let x = { ...response.data }
                if (x.colorDocumentos) {
                    this.colorDocumentos = x.colorDocumentos;
                }
            } catch (error) {
                console.log(error);
            }
        },

        async GuardaMensaje() {
            //VALIDAMOS SI YA EXISTE UN ID
            if(!this.banderaNuevo){
                if(this.chat_id===""){
                    await this.GetIdChat();
                }
            }else{
                this.banderaNuevo = false;            
            }
            //VALIDAMOS QUE SE SELECCIONE UN PACIENTE SI ES EL CASO
            if(this.tipoMensaje === "Buscar sobre un Paciente"){
                if(!this.selectPaciente){
                    this.$q.notify({
                        message: 'Seleccione un paciente de la lista para continuar',
                        type: 'warning',
                        actions: [
                        { label: 'Reply', color: 'white', handler: () => { /* ... */ } }
                        ]
                    })
                    return;
                }
            }

            this.GetAvataConstanza();
            if (!this.text) {
                this.text = null;
                return;
            }
            const fecha = await this.FechaActual();
            const objMensaje = {
                tipo: "user",
                mensaje: this.text,
                fecha: fecha,
            };
            this.mensajes.push(objMensaje);
            this.animateScroll();
            await this.PostPeticion();
            this.text = "";
        },

        animateScroll() {
            const scrollPos = this.$refs.scrollArea.getScroll();
            this.position = scrollPos.verticalSize + 100;
            this.$refs.scrollArea.setScrollPosition('vertical', this.position, 300)
        },

        async PostPeticion() {
            let paciente_id = "";
            if(this.selectPaciente){
                paciente_id = this.selectPaciente._id;
            }
            const id = this.token._id;
            const fecha = await this.FechaActual();
            this.loadingMensaje = true;
            const data = {
                texto: this.text,
                message_type: this.tipoMensaje.value,
                user_id: id,
                paciente_id: paciente_id,
                chat_id: this.chat_id,
            };
            console.log(data);
            this.respuesta = "";  // Usar una propiedad reactiva en Vue para almacenar la respuesta            

            fetch('https://api.constanza.deepaxiom.com/chat', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    this.loadingMensaje = false;

                    //VAMOS A VER SI JALA ESTE PEDO
                    const objMensaje = {
                        tipo: "assistant",
                        mensaje: "",
                        fecha: fecha,
                    };
                    this.mensajes.push(objMensaje);
                    const cont = this.mensajes.length - 1;

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const reader = response.body.getReader();
                    const decoder = new TextDecoder();
                    let buffer = '';

                    function readStream() {
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                if (buffer) {
                                    // this.respuesta += buffer;  // Asignar al data de Vue
                                    this.mensajes[cont].mensaje += buffer;  // Asignar al data de Vue
                                    this.animateScroll();
                                }
                                return;
                            }

                            const chunk = decoder.decode(value, { stream: true });
                            buffer += chunk;

                            const lines = buffer.split('\n');
                            buffer = lines.pop();

                            // this.respuesta += lines.join('\n');  // Actualizar respuesta en Vue
                            this.mensajes[cont].mensaje += lines.join('\n');
                            this.animateScroll();

                            readStream(); // Continuar leyendo el stream
                        }).catch(error => {
                            console.error('Stream reading error:', error);
                            this.respuesta += 'Error: ' + error.message;
                        });
                    }

                    readStream = readStream.bind(this);  // Enlazar `this` a la función readStream
                    readStream(); // Llamar a la función de lectura
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                    this.respuesta = 'Error: ' + error.message;
                    this.loadingMensaje = false;
                });
        },

        async FechaActual() {
            const fechaActual = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
            return fechaActual;
        },
    
        filtroPacientes(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPacientes = this.itemsPacientes.filter(v => v.paciente.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPacientes.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
    
        //PACIENTES
        async GetPacientes() {
            this.loading = true
            try {
                let response = await axios.get("Paciente/GetDatosPacientes/dev/" + this.token.username);
                let catalogo = response.data;
                this.$store.state.listaPacienteStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
    
        async GetHistorialChat(){
            await this.GetIniciales();
            await this.GetAvataConstanza();
            try{
                this.mensajes = [];
                const id = this.token._id;
                let response = await axios.get("ChatConstanza/GetHistorialChatAsync/dev/" + id);
                const x = response.data;
                if(x){

                    this.mensajes = [ ...response.data ];
                    this.chat_id = x[0]._id;
                }
                this.animateScroll();
            }catch(error){
                console.log(error);
            }
        },
    
        async GetIdChat(){
            try{
                const id = this.token._id;
                let response = await axios.get("ChatConstanza/GetHistorialChatAsync/dev/" + id);
                const x = response.data;
                if(x){
                    this.chat_id = x[0]._id;
                }
            }catch(error){
                console.log(error);
            }
        },
    
        NuevoChat(){
            this.mensajes = [];
            this.chat_id  = "";
            this.banderaNuevo = true;
        },
    }
}
</script>