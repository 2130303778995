<template>
    <div class="row q-col-gutter-sm">

        <!-- DIALOG DETALLES -->
        <q-dialog v-model="dialogDetalles" persistent transition-show="flip-down" transition-hide="flip-up">
            <q-card>
                <q-bar>
                    <div>{{ this.titulo }}</div>

                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section>
                    <q-table dense :data="detalles" :columns="columnsDetalles" row-key="descripcion" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- ANTECEDENTES HEREDOFAMILIARES -->
        <q-dialog v-model="dialogAntecedentesHeredofamiliaress" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Antecedentes Heredofamiliares</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select v-model="objeto.diagnostico" emit-value map-options @filter="filtroDiagnosticos"
                                new-value-mode="add" :options="itemsfiltroDiagnosticos" use-input input-debounce="0"
                                dense filled label="Nombre de la enfermedad" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-8">
                            <q-select v-model="objeto.familiar" new-value-mode="add" emit-value map-options
                                @filter="filtroFamiliares" :options="itemsfiltroFamiliares" use-input input-debounce="0"
                                dense filled label="Familiar con la enfermedad" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input dense type="number" v-model="objeto.edad" filled label="Edad">
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarDiagnostico()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- ALCOHOLISMO -->
        <q-dialog v-model="dialogAlcoholismo" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Alcoholismo</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-8">
                            <q-select v-model="bebida.bebida" new-value-mode="add" emit-value map-options
                                @filter="filtroBebidas" :options="itemsfiltroBebidas" use-input input-debounce="0" dense
                                filled label="Tipo de bebida alcoholica" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-2">
                            <q-select v-model="bebida.frecuencia" dense class="full-width" filled
                                :options="['DIARIO', 'SEMANAL', 'QUINCENAL', 'CADA MES']" label="Frecuencia" />
                        </div>

                        <div class="col-12 col-md-2 ">
                            <q-input v-model="bebida.numCopas" dense type="number" filled label="Número de copas">
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarBebida()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- OTRAS TOXICOMANÍAS -->
        <q-dialog v-model="dialogOtrasToxicomanias" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Otras Toxicomanías</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-10">
                            <q-select v-model="toxi.toxicomania" new-value-mode="add" emit-value map-options
                                @filter="filtroToxicomanias" :options="itemsfiltroToxicomanias" use-input
                                input-debounce="0" dense filled label="Toxicomanía" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-2">
                            <q-select v-model="toxi.frecuencia" dense class="full-width" filled
                                :options="['DIARIO', 'SEMANAL', 'QUINCENAL', 'CADA MES']" label="Frecuencia">
                            </q-select>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarToxicomania()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- ENFERMEDADES CRÓNICO DEGENERATIVAS -->
        <q-dialog v-model="dialogEnfermedadesCronicoDegenerativas" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Enfermedades Crónico Degenerativas</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-10">
                            <q-select new-value-mode="add" v-model="enfermedad.diagnostico" emit-value map-options
                                @filter="filtroEnfermedades" :options="itemsfiltroEnfermedades" use-input
                                input-debounce="0" dense filled label="Enfermedad Crónico"
                                virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-2">
                            <q-input dense type="number" filled label="Años de evolución"
                                v-model="enfermedad.añosEvolucion" />
                        </div>
                        <div class="col-12 col-md-12">
                            <q-select dense filled v-model="enfermedad.tratamientos" use-input use-chips multiple
                                input-debounce="0" @new-value="createValueTratamiento" :options="itemsfiltroTratamiento"
                                @filter="filtroTratamiento" label="Tratamiento">
                            </q-select>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEnfermedad()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- PROCEDIMIENTOS QUIRÚRGICOS PREVIOS -->
        <q-dialog v-model="dialogProcedimientosQuirurgicosPrevios" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Procedimientos Quirúrgicos Previos</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-9">
                            <q-select v-model="procedimiento.procedimiento" emit-value map-options
                                @filter="filtroProcedimientos" :options="itemsfiltroProcedimientos" use-input
                                input-debounce="0" dense filled label="Procedimiento" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-3">
                            <!-- <q-input v-model="fehaProcedimiento" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
    <q-date v-model="procedimiento.fecha">
        <div class="row items-center justify-end">
            <q-btn v-close-popup label="Ok" color="primary" flat />
        </div>
    </q-date>
</q-popup-proxy>
</q-input> -->
                            <q-input dense v-model="procedimiento.fecha" label="Fecha" mask="date" bottom-slots
                                placeholder="yyyy/MM/dd" filled>
                                <template v-slot:after>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                            <q-date v-model="procedimiento.fecha">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarProcedimiento()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- TRAUMATISMOS PREVIOS -->
        <q-dialog v-model="dialogTraumatismosPrevios" persistent>
            <q-card style="min-width: 1200px;">
                <q-bar class="bg-primary text-white">
                    <div>Traumatismos Previos</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select v-model="traumatismo.tipo" new-value-mode="add" emit-value map-options
                                @filter="filtroTraumatismos" :options="itemsfiltroTraumatismos" use-input
                                input-debounce="0" dense filled label="Tipo de traumatismo"
                                virtual-scroll-slice-size="1" />
                        </div>

                        <div class="col-12 col-md-9">
                            <q-select dense filled v-model="traumatismo.fracturas.fracturas" use-input use-chips
                                multiple input-debounce="0" @new-value="createValueFracturas"
                                :options="itemsfiltroFracturas" @filter="filtroFracturas" label="Sitio de Lesión">
                            </q-select>
                        </div>
                        <div class="col-12 col-md-3">
                            <!-- <q-input v-model="fehaFractura" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="traumatismo.fracturas.fecha">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input> -->
                            <q-input dense v-model="traumatismo.fracturas.fecha" label="Fecha" mask="date" bottom-slots
                                placeholder="yyyy/MM/dd" filled>
                                <template v-slot:after>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                            <q-date v-model="traumatismo.fracturas.fecha">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </div>
                        <div class="col-12 col-md-9">
                            <q-select dense filled v-model="traumatismo.articulaciones.articulaciones" use-input
                                use-chips multiple input-debounce="0" @new-value="createValueArticulaciones"
                                :options="itemsfiltroArticulaciones" @filter="filtroArticulaciones"
                                label="Articulaciones">
                            </q-select>
                        </div>
                        <div class="col-12 col-md-3">
                            <!-- <q-input v-model="fehaArticulaciones" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="traumatismo.articulaciones.fecha">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input> -->
                            <q-input dense v-model="traumatismo.articulaciones.fecha" label="Fecha" mask="date"
                                bottom-slots placeholder="yyyy/MM/dd" filled>
                                <template v-slot:after>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                            <q-date v-model="traumatismo.articulaciones.fecha">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </div>
                        <div class="col-12 col-md-3">
                            <!-- <q-input v-model="fehaIMasked" filled label="Traumatismo craneoncefálico/medular"
                                name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="traumatismo.fechaTraumatismo">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input> -->
                            <q-input dense v-model="traumatismo.fechaTraumatismo"
                                label="Traumatismo craneoncefálico/medular" mask="date" bottom-slots
                                placeholder="yyyy/MM/dd" filled>
                                <template v-slot:after>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                            <q-date v-model="traumatismo.fechaTraumatismo">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarTraumatismo()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- HISTORIA DEL PADECIMIENTO  -->
        <q-dialog v-model="dialogHistoriaPadecimiento" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Historia del Padecimiento</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select new-value-mode="add" v-model="padecimiento.sintoma" @filter="filtroSintomas"
                                :options="itemsfiltroSintomas" option-label="sintoma" use-input input-debounce="0"
                                hide-selected dense filled label="Síntomas" virtual-scroll-slice-size="1" fill-input />
                        </div>
                        <div class="col-12 col-md-2">
                            <q-input dense type="number" filled label="Intensidad" v-model="padecimiento.intensidad" />
                        </div>
                        <div class="col-12 col-md-10">
                            <q-select v-if="padecimiento.sintoma.localizacion == 'SI'" dense filled
                                v-model="padecimiento.partesCuerpo" use-input use-chips multiple input-debounce="0"
                                @new-value="createValuePartesCuerpo" :options="itemsfiltroPartesCuerpo"
                                @filter="filtroPartesCuerpo" label="Partes del cuerpo">
                            </q-select>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarSintoma()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>INFORME MÉDICO</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-check" @click="GenerarInformeMedico()">
                    <q-tooltip>
                        Generar Informe Medico
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->
        <div class="col-12">
            <q-bar style="background:#1A4161; color:white">
                <q-space />
                <div>Informe Médico</div>
                <q-space />
            </q-bar>
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Información del Paciente</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-check" unelevated rounded
                style="background:#1A4161; color:white" label="Generar Informe Médico"
                @click="GenerarInformeMedico()" />
            <div class="element-linea"></div>
        </div>


        <div class="col-12 col-md-5">
            <q-input v-model="consulta.apellidos" dense filled label="Apellidos del paciente" />
        </div>
        <div class="col-12 col-md-4">
            <q-input v-model="consulta.nombre" dense filled label="Nombre (s) del paciente" />
        </div>
        <div class="col-12 col-md-3">
            <!-- <q-input v-model="fechaN" filled label="Fecha de nacimiento" name="event" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" color="primary" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="consulta.fechaNacimiento">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input> -->
            <q-input dense v-model="consulta.fechaNacimiento" label="Fecha de Nacimiento" mask="date" bottom-slots
                placeholder="yyyy/MM/dd" filled>
                <template v-slot:after>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="consulta.fechaNacimiento">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>
        </div>
        <div class="col-12 col-md-5">
            <q-input v-model="consulta.genero" dense filled label="Genero" />
        </div>
        <div class="col-12 col-md-4">
            <q-input v-model="consulta.edad" dense filled label="Edad" />
        </div>
        <div class="col-12 col-md-3">
            <!-- <q-input v-model="fechaE" filled label="Fecha de emisión del informe" name="event" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" color="primary" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="consulta.fechaEmision">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input> -->
            <q-input dense v-model="consulta.fechaEmision" label="Fecha de Emisión del Informe" mask="date" bottom-slots
                placeholder="yyyy/MM/dd" filled>
                <template v-slot:after>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="consulta.fechaEmision">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>
        </div>
        <div class="col-12 ">
            <h2 class="doc-h2">Información del Diagnóstico</h2>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-2">
            <!-- <q-input v-model="fechaD" filled label="Fecha de inicio" name="event" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" color="primary" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="consulta.infoDiagnostico.fechaDiagnonostico">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input> -->
            <q-input dense v-model="consulta.infoDiagnostico.fechaDiagnonostico" label="Fecha de Nacimiento" mask="date"
                bottom-slots placeholder="yyyy/MM/dd" filled>
                <template v-slot:after>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="consulta.infoDiagnostico.fechaDiagnonostico">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>
        </div>
        <div class="col-12 col-md-12">
            <q-select v-model="consulta.infoDiagnostico.diagnosticos" use-chips multiple @filter="filtroDiagnosticos"
                @new-value="createValueDiagnosticos" :options="itemsfiltroDiagnosticos" use-input input-debounce="0"
                dense filled label="Nombre de la enfermedad" virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-12">
            <q-input v-model="consulta.infoDiagnostico.caracteristicas" label="Características del diagnóstico" filled
                type="textarea" />
        </div>
        <div class="col-12 col-md-12">
            <q-select v-model="consulta.infoDiagnostico.estadioDiagnostico" emit-value map-options
                @filter="filtroEstadioDiagnostico" :options="itemsfiltroEstadioDiagnostico" use-input input-debounce="0"
                dense filled label="Estadio al diagnóstico" virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Antecedentes Heredofamiliares</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Antecedente"
                @click="dialogAntecedentesHeredofamiliaress = true" />
            <div class="element-linea"></div>
        </div>
        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md" row-key="enfermedad"
                :data="consulta.antecHeredofammiliares" :columns="columnsDiagnosticos" :rows-per-page-options="[0]"
                separator="cell" no-data-label="Sin datos disponibles">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense @click="eliminar(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="diagnostico" :props="props">{{ props.row.diagnostico }}
                        </q-td>
                        <q-td key="familiar" :props="props">
                            {{ props.row.familiar }}
                        </q-td>
                        <q-td key="edad" :props="props">
                            {{ props.row.edad }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <div class="col-12 ">
            <h2 class="doc-h2">Antecedentes Personales No Patológicos</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-4">
            <q-select v-model="consulta.antecPersonalesNoPatologicos.hemotipo" emit-value map-options
                @filter="filtroHemotipo" :options="itemsfiltroHemotipo" use-input input-debounce="0" dense filled
                label="Hemotipo" virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="consulta.antecPersonalesNoPatologicos.alergias" dense use-input use-chips multiple
                input-debounce="0" @new-value="createValueAlergias" :options="itemsfiltroAlergias"
                @filter="filtroAlergias" label="Alergias" />
        </div>
        <div class="col-12 ">
            <h2 class="doc-h2">Exposición Biomasa</h2>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-2">
            <q-input dense type="number" v-model="consulta.antecPersonalesNoPatologicos.exposicionBiomasa.horas" filled
                label="Horas al Día" />
        </div>

        <div class="col-12 col-md-2">
            <q-input dense type="number" v-model="consulta.antecPersonalesNoPatologicos.exposicionBiomasa.años" filled
                label="Años con exposición" />
        </div>
        <div class="col-12 ">
            <h2 class="doc-h2">Tabaquismo</h2>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-2">
            <q-input dense type="number" filled label="Cigarrillos al día"
                v-model="consulta.antecPersonalesNoPatologicos.tabaquismo.numCigarros" />
        </div>

        <div class="col-12 col-md-2">
            <q-input dense type="number" filled label="Años fumando"
                v-model="consulta.antecPersonalesNoPatologicos.tabaquismo.años" />
        </div>


        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Alcoholismo</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Bebida" @click="dialogAlcoholismo = true" />
            <div class="element-linea"></div>
        </div>
        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="consulta.antecPersonalesNoPatologicos.alcoholismo" :columns="columnsBebidas"
                :rows-per-page-options="[0]" separator="cell" no-data-label="Sin datos disponibles" row-key="bebida">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense @click="eliminarBebida(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="bebida" :props="props">{{ props.row.bebida }}
                        </q-td>
                        <q-td key="frecuencia" :props="props">
                            {{ props.row.frecuencia }}
                        </q-td>
                        <q-td key="numCopas" :props="props">
                            {{ props.row.numCopas }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Otras Toxicomanías</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Toxicomanías"
                @click="dialogOtrasToxicomanias = true" />
            <div class="element-linea"></div>
        </div>
        <div class="col-12 ">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="consulta.antecPersonalesNoPatologicos.toxicomanias" :columns="columnsToxicomanias"
                :rows-per-page-options="[0]" separator="cell" no-data-label="Sin datos disponibles"
                row-key="toxicomania">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarToxicomania(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="toxicomania" :props="props">{{ props.row.toxicomania }}
                        </q-td>
                        <q-td key="frecuencia" :props="props">
                            {{ props.row.frecuencia }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <div class="col-12  q-mt-lg">
            <q-bar style="background:#1A4161; color:white">
                <q-space />
                <div>ANTECEDENTES PERSONALES PATOLÓGICOS</div>
                <q-space />
            </q-bar>
        </div>

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Enfermedades Crónico Degenerativas</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Enfermedad"
                @click="dialogEnfermedadesCronicoDegenerativas = true" />
            <div class="element-linea"></div>
        </div>

        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="consulta.antecPersonalesPatologicos.enfermedadesCronicos" :columns="columnsEnfermedadesCronicos"
                :rows-per-page-options="[0]" separator="cell" no-data-label="Sin datos disponibles"
                row-key="diagnostico">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarEnfermedad(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="diagnostico" :props="props">{{ props.row.diagnostico }}
                        </q-td>
                        <q-td key="añosEvolucion" :props="props">
                            {{ props.row.añosEvolucion }}
                        </q-td>
                        <q-td auto-width key="tramientos">
                            <q-btn size="md" color="primary" rounded flat dense @click="verTratamientos(props.row)"
                                icon="mdi-format-list-bulleted">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Ver Tratamientos</q-tooltip>
                            </q-btn>
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Procedimientos Quirúrgicos Previos</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Procedimiento"
                @click="dialogProcedimientosQuirurgicosPrevios = true" />
            <div class="element-linea"></div>
        </div>
        <!-- <span>{{ this.itmesProcedimientos }}</span> -->

        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="consulta.antecPersonalesPatologicos.prodedimientosQuirurgicos" new-value-mode="add"
                :columns="columnsProcedimientos" :rows-per-page-options="[0]" separator="cell"
                no-data-label="Sin datos disponibles" row-key="procedimiento">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarProcedimiento(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="procedimiento" :props="props">{{ props.row.procedimiento }}
                        </q-td>
                        <q-td key="fecha" :props="props">
                            {{ props.row.fecha }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>


        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Traumatismos Previos</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Traumatismo"
                @click="dialogTraumatismosPrevios = true" />
            <div class="element-linea"></div>
        </div>

        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="consulta.antecPersonalesPatologicos.traumatismosPrevios" :columns="columnsTraumatismo"
                :rows-per-page-options="[0]" separator="cell" no-data-label="Sin datos disponibles" row-key="tipo">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarTraumatismo(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipo" :props="props">{{ props.row.tipo }}
                        </q-td>
                        <q-td auto-width key="fracturas">
                            <q-btn size="md" color="primary" rounded flat dense @click="verFracturas(props.row)"
                                icon="mdi-format-list-bulleted">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Ver Sitio de Lesión</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td auto-width key="articulaciones">
                            <q-btn size="md" color="primary" rounded flat dense @click="verArticulaciones(props.row)"
                                icon="mdi-format-list-bulleted">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Ver Articulaciones</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="fechaTraumatismo" :props="props">
                            {{ props.row.fechaTraumatismo }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
            <div dense class="bg-primary text-white">
                <q-bar style="background:#1A4161; color:white">
                    <q-space />
                    <div>ANTECEDENTES ONCOLÓGICOS</div>
                    <q-space />
                </q-bar>
            </div>
        </div>

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Terapia Sistématica</div>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-12">
            <q-select filled v-model="consulta.antecOncologicos.terapias" dense use-input use-chips multiple
                input-debounce="0" @new-value="createValueTerapias" :options="itemsfiltroTerapias"
                @filter="filtroTerapias" label="Terapia sistémica" />
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Radioterapia</div>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="consulta.antecOncologicos.radioterapias" dense use-input use-chips multiple
                input-debounce="0" @new-value="createValueRadioterapias" :options="itemsfiltroRadioterapias"
                @filter="filtroRadioterapias" label="Radioterapia" />
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Procedimiento Quirúrgico</div>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="consulta.antecOncologicos.procedimientos" dense use-input use-chips multiple
                input-debounce="0" @new-value="createValueProcedimientos" :options="itemsfiltroProcedimientos"
                @filter="filtroProcedimientos" label="Procedimiento quirúrgico" />
        </div>

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Historia del Padecimiento</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Padecimiento"
                @click="dialogHistoriaPadecimiento = true" />
            <div class="element-linea"></div>
        </div>

        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="consulta.historialPadecimiento.sintomas" :columns="columnsSintonmas" :rows-per-page-options="[0]"
                separator="cell" no-data-label="Sin datos disponibles" row-key="sintoma">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width style="color:#1A4161">
                            <q-btn size="md" :click="eliminar(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="sintoma" :props="props">{{ props.row.sintoma }}
                        </q-td>
                        <q-td auto-width key="partesCuerpo">
                            <q-btn size="md" color="primary" rounded flat dense @click="verPartes(props.row)"
                                icon="mdi-format-list-bulleted">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Ver partes del cuerpo</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="intensidad" :props="props">
                            {{ props.row.intensidad }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Comentarios</div>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-12">
            <q-input v-model="consulta.comentarios" label="Comentarios adicionales" filled type="textarea" />
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsfiltroDiagnosticos: null,
            itemsfiltroDiagnosticos: null,
            itemsfiltroFamiliares: null,
            objeto: {
                diagnostico: '',
                enfermedad: '',
                edad: 0
            },
            familiar: '',
            diagnostico: '',
            columnsDiagnosticos: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'diagnostico', align: 'center', label: 'Enfermedad', field: 'diagnostico', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'familiar', align: 'center', label: 'Familiar', field: 'familiar', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'edad', align: 'center', label: 'Edad', field: 'edad', sortable: true, classes: 'text-right', },
            ],

            itemsfiltroAlergias: null,
            itemsfiltroHemotipo: null,

            bebida: {
                bebida: '',
                frecuencia: '',
                numCopas: '',
            },

            columnsBebidas: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'bebida', align: 'center', label: 'Bebida Alcoholica', field: 'bebida', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'frecuencia', align: 'center', label: 'Fecuencia', field: 'frecuencia', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'numCopas', align: 'center', label: 'Número de copas', field: 'numCopas', sortable: true, classes: 'text-right', },
            ],
            toxi: {
                toxicomania: '',
                frecuencia: '',
            },
            columnsToxicomanias: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'toxicomania', align: 'center', label: 'Toxicomanía', field: 'toxicomania', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'frecuencia', align: 'center', label: 'Fecuencia', field: 'frecuencia', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
            ],

            itemsfiltroBebidas: null,
            itemsfiltroToxicomanias: null,

            dialogDetalles: false,
            detalles: [],
            titulo: '',
            columnsDetalles: [
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
            ],
            enfermedad: {
                diagnostico: '',
                añosEvolucion: 0,
                tratamientos: []
            },
            columnsEnfermedadesCronicos: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'diagnostico', align: 'center', label: 'Enfermedad', field: 'diagnostico', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'añosEvolucion', align: 'center', label: 'Años de evolución', field: 'añosEvolucion', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'tratamientos', align: 'center', label: 'Tratamientos', field: 'tratamientos', sortable: true },
            ],
            itemsfiltroEnfermedades: null,
            itemsfiltroTratamiento: null,
            itemsfiltroProcedimiento: null,

            procedimiento: {
                procedimiento: '',
                fecha: null,
            },
            columnsProcedimientos: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'procedimiento', align: 'center', label: 'Procedimiento', field: 'procedimiento', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'fecha', align: 'center', label: 'Fecha de procedimiento', field: 'fecha', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
            ],

            traumatismo: {
                tipo: '',
                fracturas: {
                    fracturas: [],
                    fecha: null,
                },
                articulaciones: {
                    articulaciones: [],
                    fecha: null
                },
                fechaTraumatismo: null
            },
            columnsTraumatismo: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'tipo', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'fracturas', align: 'center', label: 'Sitio de Lesión', field: 'fracturas', sortable: true, },
                { name: 'articulaciones', align: 'center', label: 'Articulaciones', field: 'articulaciones', sortable: true, classes: 'text-center', },
                { name: 'fechaTraumatismo', align: 'center', label: 'Traumatismo craneoncefálico/medular', field: 'fechaTraumatismo', sortable: true, classes: 'text-center', },
            ],
            itemsfiltroTraumatismos: null,
            itemsfiltroFracturas: null,
            itemsfiltroArticulaciones: null,

            itemsfiltroTerapias: null,
            itemsfiltroRadioterapias: null,
            itemsfiltroProcedimientos: null,

            itemsfiltroPartesCuerpo: null,
            itemsfiltroSintomas: null,
            itemsfiltroDiagnosticos: null,
            padecimiento: {
                sintoma: '',
                partesCuerpo: [],
                intensidad: 0
            },

            columnsSintonmas: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'sintoma', align: 'left', label: 'Síntoma', field: 'sintoma', sortable: true },
                { name: 'partesCuerpo', align: 'center', label: 'Partes del cuerpo', field: 'partesCuerpo', sortable: true, },
                { name: 'intensidad', align: 'center', label: 'Intensidad del síntoma', field: 'intensidad', sortable: true, },
            ],

            dialogDetalles: false,
            detalles: [],
            titulo: '',
            columnsDetalles: [
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
            ],

            itemsfiltroPartesCuerpo: null,
            itemsfiltroSintomas: null,
            padecimiento: {
                sintoma: '',
                partesCuerpo: [],
                intensidad: 0
            },

            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'sintoma', align: 'center', label: 'Síntoma', field: 'sintoma', sortable: true },
                { name: 'partesCuerpo', align: 'center', label: 'Partes del cuerpo', field: 'partesCuerpo', sortable: true, },
                { name: 'intensidad', align: 'center', label: 'Intensidad del síntoma', field: 'intensidad', sortable: true, },
            ],

            itemsfiltroEstadioDiagnostico: null,
            dialogHistoriaPadecimiento: false,
            dialogTraumatismosPrevios: false,
            dialogProcedimientosQuirurgicosPrevios: false,
            dialogEnfermedadesCronicoDegenerativas: false,
            dialogOtrasToxicomanias: false,
            dialogAlcoholismo: false,
            dialogAntecedentesHeredofamiliaress: false,

        }
    },
    computed: {
        pacienteConsulta() {
            return this.$store.state.pacienteConsultaStore
        },
        consulta() {

            return this.$store.state.consultaStore.informeMedico;
        },
        itemsDiagnosticosCIE() {
            return this.$store.state.listaCatalogoDiagnosticosString
        },
        itemsFamiliares() {
            return this.$store.state.listaCatalogoFamiliares
        },
        itmesAlergias() {
            return this.$store.state.listaCatalogoAlergias
        },
        itmesHemotipos() {
            return this.$store.state.listaCatalogoHemotipos
        },
        itmesBebidas() {
            return this.$store.state.listaCatalogoBebidas
        },
        itmesToxicomanias() {
            return this.$store.state.listaCatalogoToxicomanias
        },
        itemsEnfermedadesCronicas() {
            return this.$store.state.listaCatalogoEnfermedadesCronicas
        },
        itemsTratamientos() {
            return this.$store.state.listaCatalogoTratamientos
        },
        itmesProcedimientos() {
            return this.$store.state.listaCatalogoProcedimientos
        },
        itmesTraumatismos() {
            return this.$store.state.listaCatalogoTraumatismos
        },
        itmesHuesos() {
            return this.$store.state.listaCatalogoHuesos
        },
        itmesArticulaciones() {
            return this.$store.state.listaCatalogoArticulaciones
        },
        itemsTerapias() {
            return this.$store.state.listaCatalogoTerapias
        },
        itemsRadioterapias() {
            return this.$store.state.listaCatalogoRadioterapias
        },
        itmesProcedimientos() {
            return this.$store.state.listaCatalogoProcedimientos
        },
        itemsPartesCuerpo() {
            return this.$store.state.listaCatalogoPartesCuerpo
        },
        itemsSintomas() {
            return this.$store.state.listaCatalogoSintomas
        },
        itemsEstadioDiagnostico() {
            return this.$store.state.listaCatalogoEstadioDiagnostico
        },
        fechaN() {
            moment.locale('es-mx');
            return this.consulta.fechaNacimiento ? moment.utc(this.consulta.fechaNacimiento).format('DD/MMMM/yyyy') : ''
        },
        fechaE() {
            moment.locale('es-mx');
            return this.consulta.fechaEmision ? moment.utc(this.consulta.fechaEmision).format('DD/MMMM/yyyy') : ''
        },
        fechaD() {
            moment.locale('es-mx');
            return this.consulta.infoDiagnostico.fechaDiagnonostico ? moment.utc(this.consulta.infoDiagnostico.fechaDiagnonostico).format('DD/MMMM/yyyy') : ''
        },
        fehaProcedimiento() {
            moment.locale('es-mx');
            return this.procedimiento.fecha ? moment.utc(this.procedimiento.fecha).format('DD/MMMM/yyyy') : ''
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.traumatismo.fechaTraumatismo ? moment.utc(this.traumatismo.fechaTraumatismo).format('DD/MMMM/yyyy') : ''
        },
        fehaFractura() {
            moment.locale('es-mx');
            return this.traumatismo.fracturas.fecha ? moment.utc(this.traumatismo.fracturas.fecha).format('DD/MMMM/yyyy') : ''
        },
        fehaArticulaciones() {
            moment.locale('es-mx');
            return this.traumatismo.articulaciones.fecha ? moment.utc(this.traumatismo.articulaciones.fecha).format('DD/MMMM/yyyy') : ''
        },
    },
    watch: {
    },
    created() {
    },
    methods: {
        async GenerarInformeMedico() {
            let paciente = this.pacienteConsulta._id
            try {
                let respuesta = await axios.get("Paciente/GetPaciente/dev/" + paciente);
                let response = respuesta.data
                this.$store.state.consultaStore.informeMedico.nombre = response.paciente;
                this.$store.state.consultaStore.informeMedico.apellidos = response.apellidos;
                this.$store.state.consultaStore.informeMedico.edad = response.edad;
                this.$store.state.consultaStore.informeMedico.genero = response.genero;
                this.$store.state.consultaStore.informeMedico.fechaNacimiento = response.fechaNacimiento;

                this.$store.state.consultaStore.informeMedico.infoDiagnostico.diagnosticos = response.padecimientoActual.diagnosticos
                this.$store.state.consultaStore.informeMedico.infoDiagnostico.fechaDiagnonostico = response.padecimientoActual.fechaInicio

                this.$store.state.consultaStore.informeMedico.antecHeredofammiliares = response.antecHeredofammiliares

                this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.alergias = response.antecPersonalesNoPatologicos.alergias
                this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.hemotipo = response.antecPersonalesNoPatologicos.hemotipo
                this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.exposicionBiomasa = response.antecPersonalesNoPatologicos.exposicionBiomasa
                this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.tabaquismo = response.antecPersonalesNoPatologicos.tabaquismo
                this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.toxicomanias = response.antecPersonalesNoPatologicos.toxicomanias
                this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.alcoholismo = response.antecPersonalesNoPatologicos.alcoholismo

                this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.enfermedadesCronicos = response.antecPersonalesPatologicos.enfermedadesCronicos
                this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.prodedimientosQuirurgicos = response.antecPersonalesPatologicos.prodedimientosQuirurgicos
                this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.traumatismosPrevios = response.antecPersonalesPatologicos.traumatismosPrevios

                this.$store.state.consultaStore.informeMedico.antecOncologicos.terapias = response.antecOncologicos.terapias
                this.$store.state.consultaStore.informeMedico.antecOncologicos.radioterapias = response.antecOncologicos.radioterapias
                this.$store.state.consultaStore.informeMedico.antecOncologicos.procedimientos = response.antecOncologicos.procedimientos

                this.$store.state.consultaStore.informeMedico.historialPadecimiento.sintomas = response.padecimientoActual.sintomas


            } catch (error) {
                console.log(error);
            }
        },

        filtroDiagnosticos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroDiagnosticos = this.itemsDiagnosticosCIE.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroDiagnosticos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueDiagnosticos(val, done) {
            if (val.length > 0) {
                if (!this.itemsDiagnosticosCIE.includes(val)) {
                    this.itemsDiagnosticosCIE.push(val)
                }
                done(val, 'toggle')
            }
        },

        filtroFamiliares(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroFamiliares = this.itemsFamiliares.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroFamiliares.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        agregarDiagnostico() {
            if (this.objeto.diagnostico == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una enfermedad' })
                return;
            }

            if (this.objeto.familiar == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un familiar' })
                return;
            }
            if (this.objeto.edad == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una edad mayor a 0' })
                return;
            }
            this.$store.state.consultaStore.informeMedico.antecHeredofammiliares.push(this.objeto);

            this.objeto = {
                diagnostico: '',
                familiar: '',
                edad: 0
            }
        },

        eliminar(data) {
            let editedIndex = this.$store.state.consultaStore.informeMedico.antecHeredofammiliares.indexOf(data)
            this.$store.state.consultaStore.informeMedico.antecHeredofammiliares.splice(editedIndex, 1)
        },

        filtroAlergias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroAlergias = this.itmesAlergias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroAlergias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueAlergias(val, done) {
            if (val.length > 0) {
                if (!this.itmesAlergias.includes(val)) {
                    this.itmesAlergias.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroHemotipo(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroHemotipo = this.itmesHemotipos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroHemotipo.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroBebidas(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroBebidas = this.itmesBebidas.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroBebidas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroToxicomanias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroToxicomanias = this.itmesToxicomanias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroToxicomanias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        agregarBebida() {
            if (this.bebida.bebida == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una bebida' })
                return;
            }
            if (this.bebida.frecuencia == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la frecuencia' })
                return;
            }
            if (this.bebida.numCopas == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el total de copas' })
                return;
            }
            this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.alcoholismo.push(this.bebida);

            this.bebida = {
                bebida: '',
                frecuencia: '',
                numCopas: '',
            }
        },

        eliminarBebida(data) {
            let editedIndex = this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.alcoholismo.indexOf(data)
            this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.alcoholismo.splice(editedIndex, 1)
        },

        agregarToxicomania() {
            if (this.toxi.toxicomania == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una toxicomanía' })
                return;
            }
            if (this.toxi.frecuencia == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la frecuencia' })
                return;
            }
            this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.toxicomanias.push(this.toxi);

            this.toxi = {
                toxicomania: '',
                frecuencia: ''
            }
        },

        eliminarToxicomania(data) {
            let editedIndex = this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.toxicomanias.indexOf(data)
            this.$store.state.consultaStore.informeMedico.antecPersonalesNoPatologicos.toxicomanias.splice(editedIndex, 1)
        },

        filtroEnfermedades(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroEnfermedades = this.itemsEnfermedadesCronicas.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroEnfermedades.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroTratamiento(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroTratamiento = this.itemsTratamientos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroTratamiento.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        createValueTratamiento(val, done) {
            if (val.length > 0) {
                if (!this.itemsTratamientos.includes(val)) {
                    this.itemsTratamientos.push(val)
                }
                done(val, 'toggle')
            }
        },
        agregarEnfermedad() {
            if (this.enfermedad.diagnostico == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una enfermedad' })
                return;
            }
            if (this.enfermedad.tratamientos.length == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique los tratamientos' })
                return;
            }
            if (this.enfermedad.añosEvolucion == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un año mayor a 0' })
                return;
            }
            this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.enfermedadesCronicos.push(this.enfermedad);

            this.enfermedad = {
                diagnostico: '',
                tratamientos: [],
                añosEvolucion: 0
            }
        },

        eliminarEnfermedad(data) {
            let editedIndex = this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.enfermedadesCronicos.indexOf(data)
            this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.enfermedadesCronicos.splice(editedIndex, 1)
        },

        verTratamientos(data) {
            console.log(data)
            let lista = []
            for (let a of data.tratamientos) {
                let objeto = {
                    descripcion: a
                }
                lista.push(objeto)
            }
            this.detalles = lista
            this.dialogDetalles = true
            this.titulo = 'Trtamientos'
        },

        filtroProcedimientos(val, update, abort) {
            console.log(this.itmesProcedimientos)
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroProcedimiento = this.itmesProcedimientos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroProcedimiento.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        agregarProcedimiento() {
            if (this.procedimiento.procedimiento == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un procedimiento' })
                return;
            }
            if (this.procedimiento.fecha == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Elija una fecha' })
                return;
            }
            this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.prodedimientosQuirurgicos.push(this.procedimiento);

            this.procedimiento = {
                procedimiento: '',
                fecha: null
            }
        },

        eliminarProcedimiento(data) {
            let editedIndex = this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.prodedimientosQuirurgicos.indexOf(data)
            this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.prodedimientosQuirurgicos.splice(editedIndex, 1)
        },


        filtroTraumatismos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroTraumatismos = this.itmesTraumatismos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroTraumatismos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        createValueFracturas(val, done) {
            if (val.length > 0) {
                if (!this.itmesHuesos.includes(val)) {
                    this.itmesHuesos.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroFracturas(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroFracturas = this.itmesHuesos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroFracturas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueArticulaciones(val, done) {
            if (val.length > 0) {
                if (!this.itmesArticulaciones.includes(val)) {
                    this.itmesArticulaciones.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroArticulaciones(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroArticulaciones = this.itmesArticulaciones.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroArticulaciones.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        agregarTraumatismo() {


            if (this.traumatismo.tipo == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de traumatismo' })
                return;
            }
            if (this.traumatismo.fracturas.fracturas.length != 0) {
                if (this.traumatismo.fracturas.fecha == null) {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha de la lesión' })
                    return;
                }
            }

            if (this.traumatismo.articulaciones.articulaciones.length != 0) {
                if (this.traumatismo.articulaciones.fecha == null) {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha del traumatimso en articulaciones' })
                    return;
                }
            }

            this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.traumatismosPrevios.push(this.traumatismo);

            this.traumatismo = {
                tipo: '',
                fracturas: {
                    fracturas: [],
                    fecha: null,
                },
                articulaciones: {
                    articulaciones: [],
                    fecha: null,
                },
                fechaTraumatismo: null,
            }
        },
        eliminarTraumatismo(data) {
            let editedIndex = this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.traumatismosPrevios.indexOf(data)
            this.$store.state.consultaStore.informeMedico.antecPersonalesPatologicos.traumatismosPrevios.splice(editedIndex, 1)
        },
        verArticulaciones(data) {
            console.log(data)
            console.log(data)
            let lista = []
            for (let a of data.articulaciones.articulaciones) {
                let objeto = {
                    descripcion: a
                }
                lista.push(objeto)
            }
            this.detalles = lista
            this.dialogDetalles = true
            this.titulo = 'Articulaciones'
        },
        verFracturas(data) {
            console.log(data)

            let lista = []
            for (let a of data.fracturas.fracturas) {
                let objeto = {
                    descripcion: a
                }
                lista.push(objeto)
            }
            this.detalles = lista
            this.dialogDetalles = true
            this.titulo = 'Sitios de Lesión'
        },
        filtroTerapias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroTerapias = this.itemsTerapias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroTerapias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroRadioterapias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroRadioterapias = this.itemsRadioterapias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroRadioterapias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroProcedimientos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroProcedimientos = this.itmesProcedimientos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroProcedimientos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueTerapias(val, done) {
            if (val.length > 0) {
                if (!this.itemsTerapias.includes(val)) {
                    this.itemsTerapias.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueRadioterapias(val, done) {
            if (val.length > 0) {
                if (!this.itemsRadioterapias.includes(val)) {
                    this.itemsRadioterapias.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueProcedimientos(val, done) {
            if (val.length > 0) {
                if (!this.itmesProcedimientos.includes(val)) {
                    this.itmesProcedimientos.push(val)
                }
                done(val, 'toggle')
            }
        },

        filtroSintomas(val, update, abort) {
            let stringOptions = this.itemsSintomas
            if (val === '') {
                update(() => {
                    this.itemsfiltroSintomas = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.itemsfiltroSintomas = stringOptions.filter(v => v.sintoma.toLowerCase().indexOf(needle) > -1)
            })
        },
        filtroPartesCuerpo(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPartesCuerpo = this.itemsPartesCuerpo.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPartesCuerpo.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValuePartesCuerpo(val, done) {
            if (val.length > 0) {
                if (!this.itemsPartesCuerpo.includes(val)) {
                    this.itemsPartesCuerpo.push(val)
                }
                done(val, 'toggle')
            }
        },

        agregarSintoma() {
            if (this.padecimiento.sintoma == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un síntoma' })
                return;
            }
            if (this.padecimiento.partesCuerpo.length == 0 && this.padecimiento.sintoma.localizacion == 'SI') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique las partes del cuerpo' })
                return;
            }
            // if (this.padecimiento.intensidad == 0) {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la intensidad del síntoma' })
            //     return;
            // }
            this.padecimiento.sintoma = this.padecimiento.sintoma.sintoma
            this.$store.state.consultaStore.informeMedico.historialPadecimiento.sintomas.push(this.padecimiento);
            this.padecimiento = {
                sintoma: '',
                partesCuerpo: [],
                intensidad: 0
            }
        },
        eliminar(data) {
            let editedIndex = this.$store.state.consultaStore.informeMedico.historialPadecimiento.sintomas.indexOf(data)
            this.$store.state.consultaStore.informeMedico.historialPadecimiento.sintomas.splice(editedIndex, 1)
        },
        verPartes(data) {
            console.log(data);
            let lista = []
            for (let a of data.partesCuerpo) {
                let objeto = {
                    descripcion: a
                }
                lista.push(objeto)
            }
            this.detalles = lista
            this.dialogDetalles = true
            this.titulo = 'Partes del cuerpo'
        },

        filtroEstadioDiagnostico(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroEstadioDiagnostico = this.itemsEstadioDiagnostico.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroEstadioDiagnostico.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
    }
}
</script>