<template>
    <q-layout view="hHh lpR fFf">
        <q-header>
            <q-toolbar class="bg-dark">
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="irInicio" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">One Analítica</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ token.username }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer :width="350" v-model="drawerPerfil" behavior="mobile" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>
        <router-view />
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue"

export default {

    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    // created() {
    //     this.$store.dispatch("autoLogin");
    // },
    methods: {
        irInicio() {
            this.$router.push({ name: 'Home' })
        }
    }
}
</script>
<style>
.fondo {
    background-image: url('../../assets/photo-menu.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    /* Asegura que el fondo cubra toda la altura de la ventana */
    width: 100%;
    /* Opcional, asegura que también ocupe el ancho completo */
    margin: 0;
    overflow: hidden;
    /* Evita el desplazamiento */
}
</style>