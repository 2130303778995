<!-- <template>
    <div class="bg-primary window-height window-width row justify-center items-center">

        <div class="column">
            <div class="row">
                
                <q-card square bordered class="q-pa-lg shadow-1">
                    <q-card-section>
                        <q-form class="text-center">
                            <img alt="Contago logo" src="../assets/logo_black.png" style="height: 70px">
                        </q-form>
                    </q-card-section>
                    <q-card-section>
                        <q-form class="q-gutter-md">
                            <span style="color: aquamarine;">{{ this.$store.state.usuarioStore }}</span>

                            
                            <q-input square filled v-model="nombreU" type="usuario" label="Usuario" />
                            <q-input square filled v-model="password" type="password" label="Contraseña"
                                @keyup.enter="validarUsuario()" />
                        </q-form>
                    </q-card-section>
                    <q-card-actions class="q-px-md">
                        <q-btn unelevated color="primary" size="lg" class="full-width" @click="validarUsuario()"
                            label="INICIAR SESIÓN" />
                    </q-card-actions>
                </q-card>
            </div>
        </div>

    </div>

</template>

<script>
import axios from 'axios';
import { QSpinnerGears } from 'quasar';
export default {
    name: 'Login',
    data() {
        return {
            password: '',
            nombreU: "",
        }
    },
    computed: {
    },
    methods: {
        async validarUsuario() {
            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese un nombre de usuario.', timeout: 2000 })
                return
            }
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese su contraseña.', timeout: 2000 })
                return
            }
            this.$q.loading.show({ message: '<b>Validando usuario...</b>' })
            try {
                let response = await axios.get(`Login/ValidaLogin/dev/${this.nombreU}/${this.password}`);
                this.$q.loading.hide();

                let data = response.data;
                // console.log(data)
                this.$store.state.usuario = { ...data.usuario };
                console.log(data.token);
                this.$store.dispatch("guardarToken", data.token);
                this.$router.push({ name: "Home" });

                this.nombreU = "";
                this.password = "";
            } catch (err) {
                console.log(err);
                this.$q.loading.hide();

                this.$q.notify({ type: 'negative', message: err.response.data });
            }

        }
    }
}
</script>

<style>
.q-card {
    width: 500px;
}
</style> -->
<template>
    <div class="bodys">


        <div class='container-form sign-in' v-if="isLogin">
            <form class="formulario">
                <q-card class="shadow-0 transparent">
                    <q-card-section>
                        <h2 class="create-account">Iniciar Sesión</h2>

                        <p class="cuenta-gratis">¿Aun no tienes una cuenta?</p>
                        <div class="iconos">
                            <q-btn outline round size="lg" color="primary" icon="facebook" />
                            <q-btn outline round size="lg" color="primary" icon="mdi-web" />
                            <q-btn outline round size="lg" color="primary" icon="mdi-whatsapp" />
                        </div>
                    </q-card-section>

                    <q-card-section class=" q-ml-lg q-mr-lg  ">
                        <q-form class="q-gutter-md">
                            <q-input square filled v-model="nombreU" type="usuario" label="Usuario" />
                            <q-input square filled v-model="password" type="password" label="Contraseña"
                                @keyup.enter="validarUsuario()" />
                        </q-form>
                    </q-card-section>
                    <q-card-actions class="q-px-md q-ml-lg q-mr-lg">
                        <q-btn unelevated color="primary" size="lg" class="full-width" @click="validarUsuario()"
                            label="INICIAR SESIÓN" />
                    </q-card-actions>
                </q-card>
            </form>
            <div class="welcome-back">
                <div class="message">
                    <img src="../assets/logo_white.png" width="450px" alt="Logo">
                    <h2 class="create-account" style="font-size: 40px;">Bienvenido</h2>
                    <p style="margin-top: -40px; font-size: 20px;">Si aun no tienes una cuenta por favor regístrese aquí
                    </p>
                    <q-btn class="q-mt-lg" style="width: 200px;" unelevated size="md" rounded color="primary"
                        label="Registrarse" />
                    <!-- <button class="sign-in-btn" @click="toggleForm">Registrarse</button> -->
                </div>
            </div>
        </div>

        <div class='container-form sign-up' v-else>
            <div class="welcome-back">
                <div class="message">
                    <img src="../assets/logo_white.png" width="450px" alt="Logo">
                    <h2 class="create-account" style="font-size: 40px;">Bienvenido</h2>
                    <p style="margin-top: -40px; font-size: 20px;">Si ya formas parte, inicia sesión aquí.</p>
                    <q-btn class="q-mt-lg" style="width: 200px;" unelevated size="md" rounded color="primary"
                        label="Iniciar sesión" @click="login()" />
                </div>
            </div>
            <form class="formulario-dos" @submit.prevent="register">
                <h2 class="create-account">Crear una cuenta</h2>
                <div class="iconos">
                    <q-btn outline round size="lg" color="primary" icon="facebook" />
                    <q-btn outline round size="lg" color="primary" icon="mdi-web" />
                    <q-btn outline round size="lg" color="primary" icon="mdi-whatsapp" />
                </div>
                <p class="cuenta-gratis">Crear una cuenta gratis</p>

                <div class=" row q-col-gutter-sm q-ml-xl q-mr-xl">
                    <div class="col-12">
                        <q-input square filled dense label="Cédula Profesional" />
                    </div>
                    <div class="col-12">
                        <q-input square filled dense label="Nombre de usuario" />
                    </div>
                    <div class="col-12 col-md-4">
                        <q-input square filled dense label="Nombre" />
                    </div>
                    <div class="col-12 col-md-8">
                        <q-input square filled dense label="Apellidos" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input square filled dense label="Teléfono" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input square filled dense label="Email" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input square filled dense label="Género" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input square filled dense label="Fecha de Nacimiento" />
                    </div>
                    <div class="col-12">
                        <q-input square filled dense label="Password" />
                    </div>
                    <div class="col-12">
                        <q-btn color="primary" class="full-width" label="Registrarse" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { QSpinnerGears } from 'quasar';
export default {
    data() {
        return {
            password: '',
            nombreU: "",


            name: '',
            email: '',
            isSignUp: true, // Controla la vista del formulario
            isLogin: true
        };
    },
    methods: {
        toggleForm() {
            this.isSignUp = !this.isSignUp;
        },
        register() {
            this.isLogin = false
        },
        login() {
            this.isLogin = true
        },

        async validarUsuario() {
            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese un nombre de usuario.', timeout: 2000 })
                return
            }
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese su contraseña.', timeout: 2000 })
                return
            }
            this.$q.loading.show({ message: '<b>Validando usuario...</b>' })
            try {
                let response = await axios.get(`Login/ValidaLogin/dev/${this.nombreU}/${this.password}`);
                this.$q.loading.hide();

                let data = response.data;
                // console.log(data)
                this.$store.state.usuario = { ...data.usuario };
                console.log(data.token);
                this.$store.dispatch("guardarToken", data.token);
                this.$router.push({ name: "Home" });

                this.nombreU = "";
                this.password = "";
            } catch (err) {
                console.log(err);
                this.$q.loading.hide();

                this.$q.notify({ type: 'negative', message: err.response.data });
            }

        }
    }
};
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.bodys {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
}

.container-form {
    width: 70%;
    height: 70vh;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s ease-out;
}

.welcome-back {
    display: flex;
    align-items: center;
    height: 70vh;
    text-align: center;
}

.message {
    padding: 1rem;
}

.message h2 {
    font-size: 1.7rem;
    padding: 1rem 0;
}

/* .message button {
    padding: 1rem;
    font-weight: 400;
    background-color: #4a4aee;
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 2rem;
    transition: all 0.3s ease-in;
    color: #fff;
} */

.message button:hover {
    background-color: #6464f8;
}

.formulario {
    width: 500px;
    /* padding: 1rem; */
    /* margin: 2rem; */
    background-color: rgba(51, 51, 51, 0.6);
    text-align: center;
}

.formulario-dos {
    width: 700px;
    /* padding: 1rem; */
    /* margin: 2rem; */
    background-color: rgba(51, 51, 51, 0.6);
    text-align: center;
}

.create-account {
    font-size: 2rem;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.iconos {
    width: 200px;
    display: flex;
    justify-content: space-around;
    margin: auto;
}

.border-icon {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border: solid thin white;
    border-radius: 50%;
    font-size: 1.5rem;
    transition: all 0.3s ease-in;
}

.border-icon:hover {
    background-color: #4a4aee;
    cursor: pointer;
}

.cuenta-gratis {
    padding: 1rem 0;
}

.formulario input {
    width: 70%;
    display: block;
    margin: auto;
    margin-bottom: 2rem;
    background-color: transparent;
    border: none;
    border-bottom: white thin solid;
    text-align: center;
    outline: none;
    padding: 0.2rem 0;
    font-size: 0.9rem;
    color: white;
}

.formulario input[type="submit"] {
    width: 60%;
    margin: auto;
    border: solid thin white;
    padding: 0.7rem;
    border-radius: 2rem;
    background-color: white;
    font-weight: 600;
    margin-top: 3rem;
    font-size: 0.8rem;
    cursor: pointer;
    color: #222;
}
</style>