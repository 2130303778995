<template>
    <div class="row q-col-gutter-sm">
        <!-- TRAUMATISMOS PREVIOS -->
        <q-dialog v-model="dialogSitios" persistent>
            <q-card style="min-width: 1300px;">
                <q-bar class="bg-primary text-white">
                    <div>Traumatismos Previos</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-9">
                            <q-select v-model="sitio.sitio" new-value-mode="add" emit-value map-options
                                @filter="filtroSitios" :options="itemsfiltroSitios" use-input input-debounce="0" dense
                                filled label="Sitio de tumor primario" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-3">
                            <!-- <q-input v-model="fehaIMasked" filled label="Fecha de Diagnóstico" name="event" outlined
                                dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
    <q-date v-model="sitio.fechaDiagnostico">
        <div class="row items-center justify-end">
            <q-btn v-close-popup label="Ok" color="primary" flat />
        </div>
    </q-date>
</q-popup-proxy>
</q-input> -->

                            <q-input dense v-model="sitio.fechaDiagnostico" label="Fecha de Diagnóstico" mask="date"
                                bottom-slots placeholder="yyyy/MM/dd" filled>
                                <template v-slot:after>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                            <q-date v-model="sitio.fechaDiagnostico">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </div>

                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarSitio()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <div class="col-12">
            <q-bar style="background:#1A4161; color:white">
                <q-space />
                <div>TRATAMIENTOS ONCOLÓGICOS PREVIOS</div>
                <q-space />

            </q-bar>
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Diagnóstico Previo de Cáncer</div>
            <q-space />
            <q-btn v-if="paciente.diagnosticoPrevioCancer == 'Si'" size="sm" class="btn-estilos" icon="mdi-plus"
                unelevated rounded style="background:#1A4161; color:white" label="Agregar Diagnósticos previos"
                @click="dialogSitios = true" />
            <div class="element-linea"></div>
        </div>
        <div class="q-gutter-sm">
            <q-checkbox v-model="paciente.diagnosticoPrevioCancer" color="secondary"
                label="Diagnóstico previo de Cáncer." true-value="Si" false-value="No" />
        </div>
        <div v-if="paciente.diagnosticoPrevioCancer == 'Si'" class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="paciente.sitiosTumorPrimario" :columns="columns" :rows-per-page-options="[0]" separator="cell"
                no-data-label="Sin datos disponibles" row-key="sitio">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense @click="eliminarSitio(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="sitio" :props="props">{{ props.row.sitio }}
                        </q-td>
                        <q-td key="fechaDiagnostico" :props="props">
                            {{ props.row.fechaDiagnostico }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>


        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Terapia Sistémica</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="paciente.terapias" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueTerapias" :options="itemsfiltroTerapias" @filter="filtroTerapias"
                label="Terapia sistémica" />
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Radioterapia</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="paciente.radioterapias" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueRadioterapias" :options="itemsfiltroRadioterapias" @filter="filtroRadioterapias"
                label="Radioterapia" />
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Procedimiento Quirúrgico</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="paciente.procedimientos" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueProcedimientos" :options="itemsfiltroProcedimientos"
                @filter="filtroProcedimientos" label="Procedimiento quirúrgico" />
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsfiltroTerapias: null,
            itemsfiltroRadioterapias: null,
            itemsfiltroProcedimientos: null,
            itemsfiltroSitios: null,
            customModel: 'No',
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'sitio', align: 'center', label: 'Sitio de tumor primario', field: 'sitio', sortable: true },
                { name: 'fechaDiagnostico', align: 'center', label: 'Fecha de Diagnóstico', field: 'fechaDiagnostico', sortable: true, },
            ],
            sitio: {
                sitio: '',
                fechaDiagnostico: null
            },
            dialogSitios: false
        }
    },
    computed: {
        paciente() {
            return this.$store.state.pacienteStore.antecOncologicos;
        },
        itemsTerapias() {
            return this.$store.state.listaCatalogoTerapias
        },
        itemsRadioterapias() {
            return this.$store.state.listaCatalogoRadioterapias
        },
        itmesProcedimientos() {
            return this.$store.state.listaCatalogoProcedimientos
        },
        itemsSitiosPrimarios() {
            return this.$store.state.listaCatalogoSitiosPrimarios
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.sitio.fechaDiagnostico ? moment.utc(this.sitio.fechaDiagnostico).format('DD/MMMM/yyyy') : ''
        },
    },
    watch: {
    },
    created() {
    },
    methods: {
        agregarSitio() {


            if (this.sitio.sitio == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el sitio de tumor primario' })
                return;
            }

            this.$store.state.pacienteStore.antecOncologicos.sitiosTumorPrimario.push(this.sitio);

            this.sitio = {
                sitio: '',
                fechaDiagnostico: null
            }
        },
        eliminarSitio(data) {
            let editedIndex = this.$store.state.pacienteStore.antecOncologicos.sitiosTumorPrimario.indexOf(data)
            this.$store.state.pacienteStore.antecOncologicos.sitiosTumorPrimario.splice(editedIndex, 1)
        },
        filtroSitios(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroSitios = this.itemsSitiosPrimarios.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroSitios.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroTerapias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroTerapias = this.itemsTerapias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroTerapias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroRadioterapias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroRadioterapias = this.itemsRadioterapias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroRadioterapias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroProcedimientos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroProcedimientos = this.itmesProcedimientos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroProcedimientos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueTerapias(val, done) {
            if (val.length > 0) {
                if (!this.itemsTerapias.includes(val)) {
                    this.itemsTerapias.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueRadioterapias(val, done) {
            if (val.length > 0) {
                if (!this.itemsRadioterapias.includes(val)) {
                    this.itemsRadioterapias.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueProcedimientos(val, done) {
            if (val.length > 0) {
                if (!this.itmesProcedimientos.includes(val)) {
                    this.itmesProcedimientos.push(val)
                }
                done(val, 'toggle')
            }
        },
    }
}
</script>