<template>
    <div>
        <!-- <q-img class="" src="https://cdn.quasar.dev/img/material.png" style="height: 150px">
            <div class="absolute-bottom bg-transparent">
                <q-avatar size="56px" class="q-mb-sm">
                    <img src="https://cdn.quasar.dev/img/boy-avatar.png">
                </q-avatar>
                <div class="text-weight-bold">{{ token.nombre }}</div>
            </div>
        </q-img> -->
        <q-card class="q-mt-lg shadow-0">
            <q-card-section class="text-center">
                <q-avatar v-if="token.genero == 'Masculino'" style="width: 150px;height: 150px; font-size: 30px;">
                    <img src="https://cdn.quasar.dev/img/boy-avatar.png">
                </q-avatar>
                <q-avatar v-else style="width: 150px;height: 150px; font-size: 30px;">
                    <img src="https://cdn.quasar.dev/img/avatar5.jpg">
                </q-avatar>
                <p style="font-size: 25px; font-weight: bold;">{{ token.nombre }} </p>
                <p style="font-size: 15px; margin-top: -20px;" lines="1">{{ token.username + ' ' + token.apellido }}
                </p>
            </q-card-section>
        </q-card>

        <q-list class="rounded-borders" style="max-width: 350px; ">
            <q-item-label header>Menu</q-item-label>
            <q-separator inset="item" />
            <q-item class="text-primary" clickable v-ripple @click="irPerfil()">
                <q-item-section avatar>
                    <q-icon name="mdi-account" />
                </q-item-section>
                <q-item-section>
                    <q-item-label lines="1">Perfil</q-item-label>
                </q-item-section>
            </q-item>
            <q-separator inset="item" />
            <q-item class="text-primary" clickable v-ripple>
                <q-item-section avatar>
                    <q-icon name="mdi-account-group" />
                </q-item-section>
                <q-item-section>
                    <q-item-label lines="1">Empleados</q-item-label>
                </q-item-section>
            </q-item>
            <q-separator inset="item" />

        </q-list>



        <q-footer class="transparent">
            <q-list class="rounded-borders" style="max-width: 350px; ">
                <q-item-label header>Otros</q-item-label>
                <q-item class="text-primary" tag="label" v-ripple>
                    <q-item-section>
                        <q-item-label>Modo Oscuro</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-toggle color="blue" v-model="modoOscuro" val="battery" />
                    </q-item-section>
                </q-item>

                <q-separator />

                <q-item clickable active v-ripple @click="salir()">
                    <q-item-section>
                        Cerrar Sesión
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="mdi-logout" />
                    </q-item-section>
                </q-item>
            </q-list>
            <p class="text-center text-primary">soporte@oneanalitica.com</p>
        </q-footer>


    </div>
</template>
<script>
import { ref } from 'vue'
import axios from "axios";
import moment from 'moment'

export default {

    components: {
    },
    data() {
        return {
            isDarkMode: false,

        }
    },

    watch: {
        // modoOscuro(newVal) {
        //     this.$q.dark.set(newVal);
        // }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        letra() {
            return this.$store.state.usuario.nombre.charAt(0).toUpperCase();
        },
        modoOscuro: {
            get() {
                return this.$store.state.isDark;
            },
            set(value) {
                this.$store.commit('SET_DARK_MODE', value);
                this.$q.dark.set(value);
            }
        }
    },
    created() {
    },
    methods: {
        async salir() {
            this.$q.loading.show({ message: '<b>Cerrando Sesión...</b>' })
            try {
                this.$q.loading.hide()
                this.$store.dispatch("salir");
            } catch (err) {
                console.log(err);
                this.$q.loading.hide()

            }
        },

        irEmpleados() {
            this.$router.push('ListaEmpleados')
        },
        irLogin() {
            this.$router.push('Login2')
        },
        irPerfil() {
            this.$router.push('Perfil')
        }
    }
}
</script>
