<template>
    <div class="row q-col-gutter-sm">
        <!-- <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>VISUALIZACIÓN DE DATOS GENERALES</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12 ">
            <h2 class="doc-h2">Visualización de Datos Generales</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-3">
            <!-- <q-input v-model="fehaIMasked" filled label="Fecha de consulta" name="event" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" color="primary" />
                </template>
<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
    <q-date v-model="consulta.fechaConsulta">
        <div class="row items-center justify-end">
            <q-btn v-close-popup label="Ok" color="primary" flat />
        </div>
    </q-date>
</q-popup-proxy>
</q-input> -->
            <q-input dense v-model="consulta.fechaConsulta" label="Fecha de Consulta" mask="date" bottom-slots
                placeholder="yyyy/MM/dd" filled>
                <template v-slot:after>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="consulta.fechaConsulta">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>
        </div>
        <div class="col-12 col-md-12">
            <q-select v-model="$store.state.pacienteConsultaStore" @filter="filtroPacientes"
                :options="itemsfiltroPacientes" use-input input-debounce="0" option-label="paciente" dense filled
                label="Paciente" new-value-mode="add" @input="handleSeleccionPaciente" />
        </div>
        <div class="col-12 col-md-12">
            <q-select v-model="consulta.motivo" emit-value map-options @filter="filtroMotivos"
                :options="itemsfiltroMotivos" use-input input-debounce="0" dense filled label="Motivo de consulta"
                virtual-scroll-slice-size="1" new-value-mode="add" />
        </div>
        <!-- <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>PADECIMIENTO ACTUAL</div>
                <q-space />
            </q-bar>
        </div> -->
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
import { date } from 'quasar'

export default {
    components: {
    },
    data() {
        return {
            itemsfiltroPacientes: null,
            itemsfiltroMotivos: null
        }
    },
    computed: {
        consulta() {
            return this.$store.state.consultaStore;
        },
        itemsPacientes() {
            return this.$store.state.listaPacienteStore
        },
        pacienteConsulta() {
            return this.$store.state.pacienteConsultaStore
        },
        itemsMotivosConsulta() {
            return this.$store.state.listaCatalogoMotivosConsulta
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.consulta.fechaConsulta ? moment(this.consulta.fechaConsulta).format('DD/MMMM/yyyy') : ''

        },
    },
    watch: {
    },

    created() {
        this.fechaActual();
    },

    methods: {
        filtroPacientes(val, update, abort) {
            // update(() => {
            //     const needle = val.toLocaleLowerCase()
            //     this.itemsfiltroPacientes = this.itemsPacientes.filter(v => v.paciente.toLocaleLowerCase().indexOf(needle) > -1)
            // },
            //     ref => {
            //         if (val !== '' && this.itemsfiltroPacientes.length > 0) {
            //             ref.setOptionIndex(-1)
            //             ref.moveOptionSelection(1, true)
            //         }
            //     })

            let stringOptions = this.itemsPacientes
            if (val === '') {
                update(() => {
                    this.itemsfiltroPacientes = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.itemsfiltroPacientes = stringOptions.filter(v => v.paciente.toLowerCase().indexOf(needle) > -1)
            })
        },

        filtroMotivos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroMotivos = this.itemsMotivosConsulta.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroMotivos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        async handleSeleccionPaciente() {
            await this.GetMedicamentosDiagnosticos()
        },

        async GetMedicamentosDiagnosticos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 110, message: 'Consultando datos previos...', messageColor: 'white' })

            try {
                let response = await axios.get("Paciente/GetMedicamentosDiagnosticos/dev/" + this.pacienteConsulta._id);
                let catalogo = response.data;
                console.log(catalogo)
                this.$store.state.consultaStore.diagnosticoPlan.diagnosticos = catalogo.diagnosticos
                this.$store.state.consultaStore.diagnosticoPlan.medicamentos.medicamentos = catalogo.medicamentos
                this.$q.loading.hide()
                console.log(this.$store.state.consultaStore)
            } catch (error) {
                this.$q.loading.hide()
                console.log(error);
            }
        },

        fechaActual() {
            const timeStamp = Date.now()
            const formattedString = date.formatDate(timeStamp, 'YYYY/MM/DD');
            this.$store.state.consultaStore.fechaConsulta = formattedString;
        },
    }
}
</script>