<template>
    <div class="row q-col-gutter-sm">

        <!-- ESTUDIOS PREVIOS -->
        <q-dialog v-model="dialogEstudios" persistent>
            <q-card style="min-width: 1300px;">
                <q-bar class="bg-primary text-white">
                    <div>PRUEBAS DE LABORATORIO E IMAGEN</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12">
                            <q-select v-model="tipoEstudio"
                                :options="['Estudio Imagenológico', 'Estudio Laboratorio', 'Pruebas Moleculares', 'Anatomía Patológica']"
                                dense filled label="Tipo de Estudio" />
                        </div>
                        <template v-if="tipoEstudio == 'Estudio Imagenológico'">
                            <div class="col-12 col-md-4">
                                <q-select new-value-mode="add" v-model="estudioImagenologico.estudio" emit-value
                                    map-options @filter="filtroImagenologico" :options="itemsfiltroImagenologico"
                                    use-input input-debounce="0" dense filled label="Estudio Imagenológico"
                                    virtual-scroll-slice-size="1" />
                            </div>
                            <div class="col-12 col-md-4">
                                <!-- <q-input v-model="fecha1" filled label="Fecha del estudio" name="event" outlined dense>
                                    <template v-slot:before>
                                        <q-icon name="event" color="primary" />
                                    </template>
<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
    <q-date v-model="estudioImagenologico.fecha">
        <div class="row items-center justify-end">
            <q-btn v-close-popup label="Ok" color="primary" flat />
        </div>
    </q-date>
</q-popup-proxy>
</q-input> -->
                                <q-input dense v-model="estudioImagenologico.fecha" label="Fecha del Estudio"
                                    mask="date" bottom-slots placeholder="yyyy/MM/dd" filled>
                                    <template v-slot:after>
                                        <q-icon name="event" class="cursor-pointer">
                                            <q-popup-proxy ref="qDateProxy" transition-show="scale"
                                                transition-hide="scale">
                                                <q-date v-model="estudioImagenologico.fecha">
                                                    <div class="row items-center justify-end">
                                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                                    </div>
                                                </q-date>
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col-12 col-md-4">
                                <q-input class="q-px-md q-pb-md" dense @input="upload1" filled type="file"
                                    accept=".pdf, .jpeg, .png" />
                            </div>
                        </template>
                        <template v-if="tipoEstudio == 'Estudio Laboratorio'">
                            <div class="col-12 col-md-4">
                                <q-select new-value-mode="add" v-model="estudioLaboratorio.estudio" emit-value
                                    map-options @filter="filtroLaboratorio" :options="itemsfiltroLaboratorio" use-input
                                    input-debounce="0" dense filled label="Estudio Laboratorio"
                                    virtual-scroll-slice-size="1" />
                            </div>
                            <div class="col-12 col-md-4">
                                <q-input v-model="fecha2" filled label="Fecha del estudio" name="event" outlined dense>
                                    <template v-slot:before>
                                        <q-icon name="event" color="primary" />
                                    </template>
                                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                        <q-date v-model="estudioLaboratorio.fecha">
                                            <div class="row items-center justify-end">
                                                <q-btn v-close-popup label="Ok" color="primary" flat />
                                            </div>
                                        </q-date>
                                    </q-popup-proxy>
                                </q-input>
                            </div>
                            <div class="col-12 col-md-4">
                                <q-input class="q-px-md q-pb-md" dense @input="upload2" filled type="file"
                                    accept=".pdf, .jpeg, .png" />
                            </div>
                        </template>
                        <template v-if="tipoEstudio == 'Pruebas Moleculares'">
                            <div class="col-12 col-md-4">
                                <q-select new-value-mode="add" v-model="pruebasMoleculares.estudio" emit-value
                                    map-options @filter="filtroPruebas" :options="itemsfiltroPruebas" use-input
                                    input-debounce="0" dense filled label="Pruebas Moleculares"
                                    virtual-scroll-slice-size="1" />
                            </div>
                            <div class="col-12 col-md-4">
                                <q-input v-model="fecha3" filled label="Fecha del estudio" name="event" outlined dense>
                                    <template v-slot:before>
                                        <q-icon name="event" color="primary" />
                                    </template>
                                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                        <q-date v-model="pruebasMoleculares.fecha">
                                            <div class="row items-center justify-end">
                                                <q-btn v-close-popup label="Ok" color="primary" flat />
                                            </div>
                                        </q-date>
                                    </q-popup-proxy>
                                </q-input>
                            </div>
                            <div class="col-12 col-md-4">
                                <q-input class="q-px-md q-pb-md" dense @input="upload3" filled type="file"
                                    accept=".pdf, .jpeg, .png" />
                            </div>
                        </template>
                        <template v-if="tipoEstudio == 'Anatomía Patológica'">
                            <div class="col-12 col-md-4">
                                <q-select new-value-mode="add" v-model="anatomiaPatologica.estudio" emit-value
                                    map-options @filter="filtroAnatomias" :options="itemsfiltroAnatomias" use-input
                                    input-debounce="0" dense filled label="Anatomía Patológica"
                                    virtual-scroll-slice-size="1" />
                            </div>
                            <div class="col-12 col-md-4">
                                <q-input v-model="fecha4" filled label="Fecha del estudio" name="event" outlined dense>
                                    <template v-slot:before>
                                        <q-icon name="event" color="primary" />
                                    </template>
                                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                        <q-date v-model="anatomiaPatologica.fecha">
                                            <div class="row items-center justify-end">
                                                <q-btn v-close-popup label="Ok" color="primary" flat />
                                            </div>
                                        </q-date>
                                    </q-popup-proxy>
                                </q-input>
                            </div>
                            <div class="col-12 col-md-4">
                                <q-input class="q-px-md q-pb-md" dense @input="upload4" filled type="file"
                                    accept=".pdf, .jpeg, .png" />
                            </div>
                        </template>
                    </div>
                </q-card-section>
                <q-card-actions v-if="tipoEstudio == 'Estudio Imagenológico'" align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEstudio1()" />
                </q-card-actions>
                <q-card-actions v-if="tipoEstudio == 'Estudio Laboratorio'" align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEstudio2()" />
                </q-card-actions>
                <q-card-actions v-if="tipoEstudio == 'Pruebas Moleculares'" align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEstudio3()" />
                </q-card-actions>
                <q-card-actions v-if="tipoEstudio == 'Anatomía Patológica'" align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEstudio4()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <div class="col-12 ">
            <h2 class="doc-h2">Signos Vitales</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-3">
            <q-input suffix="lpm" bottom-slots :error="isFrecuenciaCardiaca" dense type="number" filled
                label="Frecuencia Cardiaca" v-model="consulta.frecuenciaCardiaca" />
        </div>
        <div class="col-12 col-md-3">
            <q-input suffix="rpm" bottom-slots :error="isFrecuenciaRespiratoria" dense type="number" filled
                label="Frecuencia Respiratoria" v-model="consulta.frecuenciaRespiratoria" />
        </div>
        <div class="col-12 col-md-3">
            <q-input suffix="mmHg" bottom-slots :error="isTAS" dense type="number" filled
                label="Tensión Arterial Sistólica (TAS)" v-model="consulta.TAS" />
        </div>
        <div class="col-12 col-md-3">
            <q-input suffix="mmHg" bottom-slots :error="isTAD" dense type="number" filled
                label="Tensión Arterial Diastólica (TAD)" v-model="consulta.TAD" />
        </div>
        <div class="col-12 col-md-3">
            <q-input bottom-slots dense type="number" filled label="Tensión Arterial Media"
                v-model="tensionArterialMedia" />
        </div>
        <div class="col-12 col-md-3">
            <q-input suffix="°C" bottom-slots :error="isTemperatura" dense type="number" filled label="Temperatura"
                v-model="consulta.temperatura" />
        </div>
        <div class="col-12 col-md-3">
            <q-input suffix="%" bottom-slots :error="isPulxiosimetria" dense type="number" filled label="Pulsioximetría"
                v-model="consulta.pulsioximetria" />
        </div>

        <div class="col-12 q-mt-lg">
            <q-bar style="background:#1A4161; color:white">
                <q-space />
                <div>HALLAZGOS DE LA EXPLOARACIÓN FÍSICA</div>
                <q-space />
            </q-bar>
        </div>
        <div class="col-12 ">
            <h2 class="doc-h2">Neurológicos</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-12">
            <q-select filled v-model="consulta.neurologicos" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueNeurologicos" :options="itemsfiltroNeurologicos" @filter="filtroNeurologicos"
                label="Neurológicos" />
        </div>

        <div class="col-12 ">
            <h2 class="doc-h2">Cabeza y Cuello</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-12">
            <q-select filled v-model="consulta.cabezaCuello" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueCabezaCuello" :options="itemsfiltroCabezaCuello" @filter="filtroCabezaCuello"
                label="Cabeza y cuello" />
        </div>


        <div class="col-12 ">
            <h2 class="doc-h2">Torax y Espalda</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-12">
            <q-select filled v-model="consulta.toraxEspalda" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueToraxEspalda" :options="itemsfiltroToraxEspalda" @filter="filtroToraxEspalda"
                label="Torax y espalda" />
        </div>

        <div class="col-12 ">
            <h2 class="doc-h2">Abdomen y Pelvis</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-12">
            <q-select filled v-model="consulta.abdomenPelvis" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueAbdomenPelvis" :options="itemsfiltroAbdomenPelvis"
                @filter="filtroToraxAbdomenPelvis" label="Abdomen y pelvis" />
        </div>
        <div class="col-12 ">
            <h2 class="doc-h2">Extremidades</h2>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="consulta.extremidades.sintomas" dense use-input use-chips multiple
                input-debounce="0" @new-value="createValueExtremidades" :options="itemsfiltroExtremidades"
                @filter="filtroExtremidades" label="Extremidades" />
        </div>
        <div class="col-12">
            <q-select dense filled v-model="consulta.extremidades.partesCuerpo" use-input use-chips multiple
                input-debounce="0" @new-value="createValuePartesCuerpo" :options="itemsfiltroPartesCuerpo"
                @filter="filtroPartesCuerpo" label="Partes del cuerpo">
            </q-select>
        </div>

        <div class="col-12 ">
            <h2 class="doc-h2">Tegumentos</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 ">
            <q-select filled v-model="consulta.tegumentos.sintomas" dense use-input use-chips multiple
                input-debounce="0" @new-value="createValueTegumentos" :options="itemsfiltroTegumentos"
                @filter="filtroTegumentos" label="Tegumentos" />
        </div>
        <div class="col-12 ">
            <q-select dense filled v-model="consulta.tegumentos.partesCuerpo" use-input use-chips multiple
                input-debounce="0" @new-value="createValuePartesCuerpo" :options="itemsfiltroPartesCuerpo"
                @filter="filtroPartesCuerpo" label="Partes del cuerpo">
            </q-select>
        </div>
        <!-- <div class="col-12 q-mt-lg">
            <q-bar style="background:#1A4161; color:white">
                <q-space />
                <div>PRUEBAS DE LABORATORIO E IMAGEN</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Pruebas de Laboratorio e Imagen</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar estudios" @click="dialogEstudios = true" />
            <div class="element-linea"></div>
        </div>
        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="consulta.pruebasLaboratorioImagen" :columns="columns" :rows-per-page-options="[0]"
                separator="cell" no-data-label="Sin datos disponibles" row-key="sitio">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarEstudio(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipo" :props="props">{{ props.row.tipo }}
                        </q-td>
                        <q-td key="estudio" :props="props">
                            {{ props.row.estudio }}
                        </q-td>
                        <q-td key="fecha" :props="props">
                            {{ props.row.fecha }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsfiltroPacientes: null,
            itemsfiltroAbdomenPelvis: null,
            itemsfiltroCabezaCuello: null,
            itemsfiltroExtremidades: null,
            itemsfiltroNeurologicos: null,
            itemsfiltroTegumentos: null,
            itemsfiltroToraxEspalda: null,
            itemsfiltroPartesCuerpo: null,

            itemsfiltroImagenologico: null,
            itemsfiltroPruebas: null,
            itemsfiltroLaboratorio: null,
            itemsfiltroAnatomias: null,

            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'tipo', align: 'center', label: 'Tipo de estudio', field: 'tipo', sortable: true },
                { name: 'estudio', align: 'center', label: 'Estudio', field: 'estudio', sortable: true, },
                { name: 'fecha', align: 'center', label: 'Fecha de estudio', field: 'fecha', sortable: true, },
            ],
            dialogEstudios: false,
            tipoEstudio: 'Estudio Imagenológico',
            estudioImagenologico: {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            },
            estudioLaboratorio: {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            },
            pruebasMoleculares: {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            },
            anatomiaPatologica: {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            },
        }
    },
    computed: {
        consulta() {
            return this.$store.state.consultaStore.analisisObjetivo;
        },
        itemsPartesCuerpo() {
            return this.$store.state.listaCatalogoPartesCuerpo
        },
        itemsAbdomenPelvis() {
            return this.$store.state.listaCatalogoAbdomenPelvis
        },
        itemsCabezaCuello() {
            return this.$store.state.listaCatalogoCabezaCuello
        },
        itemsExtremidades() {
            return this.$store.state.listaCatalogoExtremidades
        },
        itemsNeurologicos() {
            return this.$store.state.listaCatalogoNeurologicos
        },
        itemsTegumentos() {
            return this.$store.state.listaCatalogoTegumentos
        },
        itemsToraxEspalda() {
            return this.$store.state.listaCatalogoToraxEspalda
        },
        itemsEstudiosImagenologicos() {
            return this.$store.state.listaCatalogoEstudiosImagenologicos
        },
        itemsEstudiosLaboratorio() {
            return this.$store.state.listaCatalogoEstudiosLaboratorio
        },
        itemsEstudiosMoleculares() {
            return this.$store.state.listaCatalogoEstudiosMoleculares
        },
        itemsAnatomiaPatologica() {
            return this.$store.state.listaCatalogoEstudiosAnatomiaPatologica
        },
        isFrecuenciaCardiaca() {
            if (this.consulta.frecuenciaCardiaca < 60 || this.consulta.frecuenciaCardiaca > 100) {
                return true
            } else {
                return false
            }
        },
        isFrecuenciaRespiratoria() {
            if (this.consulta.frecuenciaRespiratoria < 12 || this.consulta.frecuenciaRespiratoria > 20) {
                return true
            } else {
                return false
            }
        },
        isTAS() {
            if (this.consulta.TAS < 100 || this.consulta.TAS > 139) {
                return true
            } else {
                return false
            }
        },
        isTAD() {
            if (this.consulta.TAD < 60 || this.consulta.TAD > 79) {
                return true
            } else {
                return false
            }
        },
        tensionArterialMedia() {
            let a = parseFloat(this.consulta.TAS) - parseFloat(this.consulta.TAD)
            let b = a / 3
            let c = b + parseFloat(this.consulta.TAD)
            this.consulta.tensionArterialMedia = c.toFixed(2);

            return this.consulta.tensionArterialMedia
        },
        isTemperatura() {
            if (this.consulta.temperatura < 35.5 || this.consulta.temperatura > 37.4) {
                return true
            } else {
                return false
            }
        },

        isPulxiosimetria() {
            if (this.consulta.pulsioximetria < 90) {
                return true
            } else {
                return false
            }
        },
        fecha1() {
            moment.locale('es-mx');
            return this.estudioImagenologico.fecha ? moment.utc(this.estudioImagenologico.fecha).format('DD/MMMM/yyyy') : ''
        },
        fecha2() {
            moment.locale('es-mx');
            return this.estudioLaboratorio.fecha ? moment.utc(this.estudioLaboratorio.fecha).format('DD/MMMM/yyyy') : ''
        },
        fecha3() {
            moment.locale('es-mx');
            return this.pruebasMoleculares.fecha ? moment.utc(this.pruebasMoleculares.fecha).format('DD/MMMM/yyyy') : ''
        },
        fecha4() {
            moment.locale('es-mx');
            return this.anatomiaPatologica.fecha ? moment.utc(this.anatomiaPatologica.fecha).format('DD/MMMM/yyyy') : ''
        },
    },
    watch: {
    },
    created() {

    },
    methods: {
        filtroCabezaCuello(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroCabezaCuello = this.itemsCabezaCuello.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroCabezaCuello.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroNeurologicos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroNeurologicos = this.itemsNeurologicos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroNeurologicos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroTegumentos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroTegumentos = this.itemsTegumentos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroTegumentos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroToraxAbdomenPelvis(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroAbdomenPelvis = this.itemsAbdomenPelvis.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroAbdomenPelvis.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroToraxEspalda(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroToraxEspalda = this.itemsToraxEspalda.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroToraxEspalda.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroExtremidades(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroExtremidades = this.itemsExtremidades.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroExtremidades.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueAbdomenPelvis(val, done) {
            if (val.length > 0) {
                if (!this.itemsAbdomenPelvis.includes(val)) {
                    this.itemsAbdomenPelvis.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueCabezaCuello(val, done) {
            if (val.length > 0) {
                if (!this.itemsCabezaCuello.includes(val)) {
                    this.itemsCabezaCuello.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueExtremidades(val, done) {
            if (val.length > 0) {
                if (!this.itemsExtremidades.includes(val)) {
                    this.itemsExtremidades.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueNeurologicos(val, done) {
            if (val.length > 0) {
                if (!this.itemsNeurologicos.includes(val)) {
                    this.itemsNeurologicos.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueTegumentos(val, done) {
            if (val.length > 0) {
                if (!this.itemsTegumentos.includes(val)) {
                    this.itemsTegumentos.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueToraxEspalda(val, done) {
            if (val.length > 0) {
                if (!this.itemsToraxEspalda.includes(val)) {
                    this.itemsToraxEspalda.push(val)
                }
                done(val, 'toggle')
            }
        },

        filtroPartesCuerpo(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPartesCuerpo = this.itemsPartesCuerpo.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPartesCuerpo.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValuePartesCuerpo(val, done) {
            if (val.length > 0) {
                if (!this.itemsPartesCuerpo.includes(val)) {
                    this.itemsPartesCuerpo.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroImagenologico(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroImagenologico = this.itemsEstudiosImagenologicos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroImagenologico.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroLaboratorio(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroLaboratorio = this.itemsEstudiosLaboratorio.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroLaboratorio.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroPruebas(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPruebas = this.itemsEstudiosMoleculares.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPruebas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroAnatomias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroAnatomias = this.itemsAnatomiaPatologica.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroAnatomias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        async upload1(event) {
            const file = event[0];
            try {
                const result = await this.convertBase64(file);

                this.estudioImagenologico.base64 = result;
                this.estudioImagenologico.nombreArchivo = file.name;
                this.estudioImagenologico.tipoArchivo = file.type;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },

        async upload2(event) {
            const file = event[0];
            try {
                const result = await this.convertBase64(file);

                this.estudioLaboratorio.base64 = result;
                this.estudioLaboratorio.nombreArchivo = file.name;
                this.estudioLaboratorio.tipoArchivo = file.type;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },

        async upload3(event) {
            const file = event[0];
            try {
                const result = await this.convertBase64(file);

                this.pruebasMoleculares.base64 = result;
                this.pruebasMoleculares.nombreArchivo = file.name;
                this.pruebasMoleculares.tipoArchivo = file.type;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        async upload4(event) {
            const file = event[0];
            try {
                const result = await this.convertBase64(file);

                this.anatomiaPatologica.base64 = result;
                this.anatomiaPatologica.nombreArchivo = file.name;
                this.anatomiaPatologica.tipoArchivo = file.type;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        agregarEstudio1() {
            this.estudioImagenologico.tipo = this.tipoEstudio

            this.$store.state.consultaStore.analisisObjetivo.pruebasLaboratorioImagen.push(this.estudioImagenologico);
            this.estudioImagenologico = {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            }
        },
        agregarEstudio2() {
            this.estudioLaboratorio.tipo = this.tipoEstudio

            this.$store.state.consultaStore.analisisObjetivo.pruebasLaboratorioImagen.push(this.estudioLaboratorio);
            this.estudioLaboratorio = {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            }
        },
        agregarEstudio3() {
            this.pruebasMoleculares.tipo = this.tipoEstudio

            this.$store.state.consultaStore.analisisObjetivo.pruebasLaboratorioImagen.push(this.pruebasMoleculares);
            this.pruebasMoleculares = {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            }
        },
        agregarEstudio4() {
            this.anatomiaPatologica.tipo = this.tipoEstudio

            this.$store.state.consultaStore.analisisObjetivo.pruebasLaboratorioImagen.push(this.anatomiaPatologica);
            this.anatomiaPatologica = {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            }
        },
        eliminarEstudio(data) {
            let editedIndex = this.$store.state.consultaStore.analisisObjetivo.pruebasLaboratorioImagen.indexOf(data)
            this.$store.state.consultaStore.analisisObjetivo.pruebasLaboratorioImagen.splice(editedIndex, 1)
        },
        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },
    }
}
</script>