<template>
    <div class="q-pa-md">
        <div class="row">
            <div class="col text-left">
                <span v-if="$store.state.isDark == true"
                    class="q-pa-lg shadow-6 mb-3 inline-block surface-card logo-inicio"
                    style="border-radius: 10px; width: 230px; height: 50px;">
                </span>

                <span v-else class="q-pa-lg shadow-0 mb-3 inline-block surface-card logo-inicio-negro"
                    style=" width: 230px; height: 50px;">
                </span>
            </div>
            <div class="col text-center">
                <div class="text-h4">Perfil</div>
            </div>
            <div class="col text-right">
            </div>
        </div>
        <!-- PERFIL MEDICO -->
        <q-expansion-item class="shadow-1 overflow-hidden" label="Perfil médico" header-class="text-white"
            expand-icon-class="text-white" flat v-model="expandedMedico" style="background:#1A4161;" switch-toggle-side>
            <q-card class="full-width">
                <q-card-section>
                    <div class="row">
                        <!-- APARTADO PARA LA FOTO -->
                        <div class="col-12 col-md-3">
                            <q-card class="full-width">
                                <q-card-section>
                                    <div class="row q-col-gutter-sm">
                                        <!-- NOMBRE DEL MEDICO -->
                                        <div class="col-12">
                                            <q-img :src="urlMedico" spinner-color="white"
                                                style="max-width: 400px; height: 300px;" contain transition="fade" />
                                        </div>
                                    </div>
                                </q-card-section>
                            </q-card>
                        </div>
                        <!-- APARTADO PARA LOS DATOS DEL MEDICO -->
                        <div class="col-12 col-md-9">
                            <div class="row q-col-gutter-sm">
                                <!-- NOMBRE DEL MEDICO -->
                                <div class="col-12">
                                    <q-input dense filled v-model="nombreMedico" label="Nombre completo" />
                                </div>
                                <!-- INTITUCION QUE EMITE EL TITULO -->
                                <div class="col-12">
                                    <q-input dense filled v-model="institucion"
                                        label="Institución que emite el título" />
                                </div>
                                <!-- ESPECIALIDAD -->
                                <div class="col-12">
                                    <q-input dense filled v-model="especialidad" label="Especialidad" />
                                </div>
                                <!-- CEDULA PROFESIONAL -->
                                <div class="col-12">
                                    <q-input dense filled v-model="cedulaProfesional" label="Cédula profesional" />
                                </div>
                                <!-- SUBIR FOTO DE PERFIL -->
                                <div class="col-12">
                                    <q-input dense @input="upload1" filled type="file" accept=".png"
                                        hint="Seleccione una foto de perfil" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                                style="background:#1A4161; color:white" @click="PostPerfilMedico()">
                                Guardar perfil médico
                            </q-btn>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-expansion-item>
        <!-- DATOS DE FACTURACION -->
        <q-expansion-item class="shadow-1 overflow-hidden" label="Datos para facturación" header-class="text-white"
            expand-icon-class="text-white" flat v-model="expandedFacturacion" style="background:#1A4161;">
            <q-card class="full-width">
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <!-- NOMBRE DEL MEDICO -->
                        <div class="col-12">
                            <q-input dense filled v-model="razonSocial" label="Razón social" />
                        </div>
                        <!-- INTITUCION QUE EMITE EL TITULO -->
                        <div class="col-12 col-md-8">
                            <q-input dense filled v-model="rfc" label="RFC" />
                        </div>
                        <!-- ESPECIALIDAD -->
                        <div class="col-12 col-md-4">
                            <q-input dense filled v-model="domiciliofiscal" label="Domicilio fiscal"
                                hint="Código postal" />
                        </div>
                        <div class="col-12">
                            <q-select filled option-label="regimenFiscal" dense v-model="regimenFiscal"
                                :options="itemsRegimenFiscal" label="Régimen Fiscal" />
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                                style="background:#1A4161; color:white" @click="PostDatosFactutracion()">
                                Guardar datos de facturación
                            </q-btn>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-expansion-item>
        <!-- CONFIGURACION DE DOCUMENTOS -->
        <q-expansion-item class="shadow-1 overflow-hidden" label="Configuración de documentos" header-class="text-white"
            expand-icon-class="text-white" flat v-model="expandedDocumentos" style="background:#1A4161;">
            <q-card class="full-width">
                <q-card-section>
                    <div class="row">
                        <div class="col-12">
                            <div class="doc-h2">Color de los documentos</div>
                            <div class="element-linea"></div>
                        </div>
                        <div class="col-12">
                            <q-input filled v-model="colorDocumentos" :rules="['anyColor']" dense
                                :style="{ color: colorDocumentos }">
                                <template v-slot:append>
                                    <q-icon name="colorize" class="cursor-pointer" :style="{ color: colorDocumentos }">
                                        <q-popup-proxy transition-show="scale" transition-hide="scale">
                                            <q-color v-model="colorDocumentos" />
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </div>

                    </div>
                    <br>
                    <!-- IMAGEN EN DOCUMENTO -->
                    <div class="row">
                        <div class="col-12">
                            <div class="doc-h2">Imagen en documento</div>
                            <div class="element-linea"></div>
                        </div>
                        <!-- LOGO -->
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <q-img :src="urlLogo" spinner-color="white" style="max-width: 400px; height: 300px;"
                                    contain transition="fade">
                                    <div class="absolute-bottom text-subtitle1 text-center">
                                        Logo
                                    </div>
                                </q-img>
                            </div>
                            <div class="row">
                                <q-input dense @input="upload2" filled type="file" accept=".png"
                                    hint="Seleccione un logo" />
                            </div>
                        </div>
                        <!-- BANNER -->
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <q-img :src="urlBanner" spinner-color="white" style="max-width: 400px; height: 300px;"
                                    contain transition="fade">
                                    <div class="absolute-bottom text-subtitle1 text-center">
                                        Banner
                                    </div>
                                </q-img>
                            </div>
                            <div class="row">
                                <q-input dense @input="upload3" filled type="file" accept=".png"
                                    hint="Seleccione un banner" />
                            </div>
                        </div>
                        <!-- SELECCION DE TIPO DE IMAGEN -->
                        <div class="col-12">
                            <q-select filled option-label="regimenFiscal" dense v-model="imagenDocumento"
                                :options="itemsImagen" label="Seleccione la imagen que se mostrará en los documentos" />
                        </div>
                    </div>
                    <br>
                    <!-- PIE DE PAGINA -->
                    <div class="row">
                        <div class="col-12">
                            <div class="doc-h2">Pie de página</div>
                            <div class="element-linea"></div>
                        </div>
                        <div class="col-12">
                            <q-editor v-model="piePagina" min-height="5rem" />
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                                style="background:#1A4161; color:white" @click="PostConfigDoc()">
                                Guardar configuración de documentos
                            </q-btn>
                        </div>
                    </div>
                </q-card-section>
            </q-card>

        </q-expansion-item>
    </div>
</template>

<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import { date } from 'quasar'
import moment from 'moment';

export default {
    components: {

    },
    data() {
        return {
            expandedMedico: true,
            expandedFacturacion: false,
            expandedDocumentos: false,

            //PERFIL MEDICO
            idPerfilMedico: "",
            nombreMedico: "",
            cedulaProfesional: "",
            especialidad: "",
            institucion: "",
            urlMedico: require('../../../assets/logo_contago.png'),

            //DATOS DE FACTURACION
            idDatosFacturacion: "",
            razonSocial: "",
            rfc: "",
            domiciliofiscal: "",
            regimenFiscal: null,
            itemsRegimenFiscal: [],
            itemsRegimenFiscalDefault: [],

            //CONFIGURACION DE DOCUMENTOS
            idDocumentos: "",
            colorDocumentos: "#42B983",
            urlBanner: require('../../../assets/logo_contago.png'),
            urlLogo: require('../../../assets/logo_contago.png'),
            imagenDocumento: null,
            itemsImagen: ["Logo", "Banner"],
            piePagina: "",

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        idMedico() {
            //OBTENEMOS EL ID DEL USUARIO ACTIVO
            const listUsuarios = this.$store.state.listaEmpleadosStore;
            const usuario = this.token.username;
            const idUsuario = listUsuarios.find((f) => f.username === usuario)._id;
            return idUsuario;
        },
    },

    watch: {

    },

    created() {
        this.Iniciales();
    },

    methods: {
        async Iniciales() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Consultanod datos...', messageColor: 'white' })
            await this.GetRegimenFiscal();
            await this.GetEmpleados();
            await this.GetPerfilMedico();
            await this.GetDatosFacturacion();
            await this.GetConfiguracionDocumentos();
            this.$q.loading.hide()
        },

        async GetRegimenFiscal() {
            try {
                let response = await axios.get("https://api-erp.contago.com.mx/api/CatalogosSat/GetRegimenFiscal");
                this.itemsRegimenFiscalDefault = [...response.data]
                this.itemsRegimenFiscal = [...response.data]
            } catch (error) {
                console.log(error);
            }
        },

        async GetEmpleados() {
            try {
                let response = await axios.get("Login/GetEmpleados/dev");
                let catalogo = response.data;
                this.$store.state.listaEmpleadosStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async upload1(event) {
            const file = event[0];
            if (file) {
                try {
                    const result = await this.convertBase64(file);
                    this.urlMedico = result;
                } catch (error) {
                    console.log(error)
                    return;
                }
            }
        },

        async upload2(event) {
            const file = event[0];
            if (file) {
                try {
                    const result = await this.convertBase64(file);
                    this.urlLogo = result;
                } catch (error) {
                    console.log(error);
                    return;
                }
            }
        },

        async upload3(event) {
            const file = event[0];
            if (file) {
                try {
                    const result = await this.convertBase64(file);
                    this.urlBanner = result;
                } catch (error) {
                    console.log(error);
                    return;
                }
            }
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        //POST
        async PostPerfilMedico() {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando perfil médico...', messageColor: 'white' })
                const objPerfil = {
                    _id: this.idPerfilMedico,
                    _idMedico: this.idMedico,
                    nombreMedico: this.nombreMedico,
                    cedulaProfesional: this.cedulaProfesional,
                    especialidad: this.especialidad,
                    institucion: this.institucion,
                    urlMedico: this.urlMedico,
                    id_codi: 0,
                }
                let response = await axios.post("Configuracion/PostPerfilMedicoAsync/dev/", objPerfil);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive',
                    message: 'Perfil médico guardado con éxito',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'negative',
                    message: 'Error al guardar, intente nuevamente',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
            }
        },

        async PostDatosFactutracion() {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando datos de facturación...', messageColor: 'white' })
                const objFacturacion = {
                    _id: this.idDatosFacturacion,
                    _idMedico: this.idMedico,
                    razonSocial: this.razonSocial,
                    rfc: this.rfc,
                    domiciliofiscal: this.domiciliofiscal,
                    regimenFiscal: {
                        clave: this.regimenFiscal.clave,
                        descripcion: this.regimenFiscal.descripcion,
                    }
                }
                let response = await axios.post("Configuracion/PostDatosFactutracionAsync/dev/", objFacturacion);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive',
                    message: 'Datos de facturación guardados con éxito',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'negative',
                    message: 'Error al guardar, intente nuevamente',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
            }
        },

        async PostConfigDoc() {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando configuración de documentos...', messageColor: 'white' })
                const objConfigDoc = {
                    _id: this.idDocumentos,
                    _idMedico: this.idMedico,
                    colorDocumentos: this.colorDocumentos,
                    urlBanner: this.urlBanner,
                    urlLogo: this.urlLogo,
                    imagenDocumento: this.imagenDocumento,
                    piePagina: this.piePagina,

                }
                let response = await axios.post("Configuracion/PostConfigDocumentosAsync/dev/", objConfigDoc);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive',
                    message: 'Configuración de documentos guardados con éxito',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'negative',
                    message: 'Error al guardar, intente nuevamente',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
            }
        },

        //GET
        async GetPerfilMedico() {
            try {
                let response = await axios.get("Configuracion/GetPerfilMedicoAsync/dev/" + this.idMedico);
                let x = { ...response.data }
                this.idPerfilMedico = x._id
                this.nombreMedico = x.nombreMedico
                this.cedulaProfesional = x.cedulaProfesional
                this.especialidad = x.especialidad
                this.institucion = x.institucion
                this.urlMedico = x.urlMedico

            } catch (error) {
                console.log(error);
            }
        },

        async GetDatosFacturacion() {
            try {
                let response = await axios.get("Configuracion/GetDatosFacturacionAsync/dev/" + this.idMedico);
                let x = { ...response.data }
                this.idDatosFacturacion = x._id
                this.razonSocial = x.razonSocial
                this.rfc = x.rfc
                this.domiciliofiscal = x.domiciliofiscal
                this.regimenFiscal = this.itemsRegimenFiscal.find((f) => f.clave === x.regimenFiscal.clave);
            } catch (error) {
                console.log(error);
            }
        },

        async GetConfiguracionDocumentos() {
            try {
                let response = await axios.get("Configuracion/GetConfiguracionDocumentosAsync/dev/" + this.idMedico);
                let x = { ...response.data }
                this.idDocumentos = x._id
                this.colorDocumentos = x.colorDocumentos
                this.urlBanner = x.urlBanner
                this.urlLogo = x.urlLogo
                this.imagenDocumento = x.imagenDocumento
                this.piePagina = x.piePagina

            } catch (error) {
                console.log(error);
            }
        },

    }
}
</script>